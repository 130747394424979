import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from 'components/home/landing';
import Company from 'components/home/company';
import How from 'components/home/how';
import Buyer from 'components/home/buyer';
import Investor from 'components/home/investor';

const Home = () => (
  <Routes>
    <Route path="/" element={<Landing />} />
    <Route path="/company" element={<Company />} />
    <Route path="/how" element={<How />} />
    <Route path="/buyer" element={<Buyer />} />
    <Route path="/investor" element={<Investor />} />
  </Routes>
);

export default Home;
