import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import Field, { FieldProps } from 'components/shared/fields/field';

export interface MoneyFieldProps extends Omit<FieldProps, 'mask'> {
  allowDecimal?: boolean;
}

const MoneyField = ({
  field,
  inputProps = {},
  allowDecimal = false,
  ...props
}: MoneyFieldProps) => {
  const convertToNumber: (val: string) => number = allowDecimal
    ? (val) => parseFloat(parseFloat(val).toFixed(2))
    : (val) => parseInt(val, 10);
  const replaceRegex = allowDecimal ? /[^\d.]+/g : /\D+/g;

  return (
    <Observer>
      {() => {
        const [inputtingDecimal, setInputtingDecimal] = useState(false);
        const localizedValue = (val: number | null): string | null => {
          if (!val) return null;

          const localized = val.toLocaleString('en-US');
          return inputtingDecimal ? `${localized}.` : localized;
        };

        return (
          <Field
            field={field}
            inputProps={{
              ...inputProps,
              onChange: ({ target: { value } }) => {
                const val = value.replace(replaceRegex, '');
                if (val.match(/\.$/)) setInputtingDecimal(true);
                else if (inputtingDecimal) setInputtingDecimal(false);
                field.set(val ? convertToNumber(val) : null);
              },
              value: `$${localizedValue(field.value) ?? ''}`,
            }}
            {...props}
          />
        );
      }}
    </Observer>
  );
};

export default MoneyField;
