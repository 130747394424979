export interface ImagePaths {
  'family.png': string;
  'family2.jpg': string;
  'house.png': string;
  'house_angle.png': string;
  'house_light_blue.png': string;
  'moving.png': string;
  'property-placeholder.jpg': string;
  'landing-fam1.jpg': string;
  'landing-fam2.jpg': string;
}

interface Window {
  [key: string]: never;
}

export default (window as unknown as Window).imagePaths as ImagePaths;
