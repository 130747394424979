import React from 'react';
import imagePaths from 'utils/image_paths';
import InfoPageLayout from 'components/home/shared/info_page_layout';

const Buyer = () => (
  <InfoPageLayout
    i18nPrefix="pages.home.buyer"
    headerImage={imagePaths['moving.png']}
    sections={{
      helpsYouBuy: ['downPaymentProblem', 'downPaymentSolution'],
      stableHousingCosts: ['landlords', 'fixedMortgage'],
      payYourOwnMortgage: ['payingOtherMortgage', 'buildEquity'],
    }}
    leadFormUserType="buyer"
  />
);

export default Buyer;
