import React, { useEffect, useState } from 'react';
import useRootStore from 'hooks/use_root_store';
import Loading from 'components/shared/loading';

const FeatureGate = ({
  children,
  feature,
  initiallyEnabled = false,
  invert = false,
  fallback,
  loadingComponent,
}: {
  children: JSX.Element;
  feature: string;
  initiallyEnabled?: boolean;
  invert?: boolean;
  fallback?: JSX.Element;
  loadingComponent?: JSX.Element;
}) => {
  const rootStore = useRootStore();
  const [enabled, setEnabled] = useState(initiallyEnabled);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    rootStore.api
      .getFeatures()
      .then((features) => setEnabled(features[feature]))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [feature]);

  if (loading) {
    if (loadingComponent === undefined) return <Loading />;
    return loadingComponent;
  }

  const show = invert ? !enabled : enabled;
  if (!show) return fallback;

  return children;
};

export default FeatureGate;
