import React from 'react';
import SensitiveText from 'components/shared/sensitive_text';

const ssnText = (ssn: string): string => `${ssn.substring(0, 3)}-${ssn.substring(2, 4)}-${ssn.substring(4, 8)}`;

export default ({ ssn }: { ssn: string }) => {
  if (ssn.length !== 9) return null;

  return (
    <SensitiveText sensitiveText={ssnText(ssn)} maskedText="xxx-xx-xxxx" />
  );
};
