import React, { useEffect, useMemo, useState } from 'react';
import Loading from 'components/shared/loading';
import MobxReactForm from 'mobx-react-form';
import {
  Box,
  Button,
  Heading,
  Text,
  Icon,
  Stack,
  HStack,
} from '@chakra-ui/react';
import Field from 'components/shared/fields/field';
import { createForm, FormContext, handleSubmitError } from 'forms/base_form';
import AddressField from 'components/shared/fields/address_field';
import { observer, Observer } from 'mobx-react';
import { deepCamelToSnakeCase } from 'utils/string_conversion';
import useRootStore from 'hooks/use_root_store';
import { useNavigate } from 'react-router-dom';
import fields from './fields';

export default observer(({ edit = false }: { edit?: boolean }) => {
  const rootStore = useRootStore();
  const {
    api,
    user: { profile, bankAccount },
  } = rootStore;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const form = useMemo<MobxReactForm>(
    () => createForm({
      fields: fields(profile, bankAccount),
      hooks: {
        onSuccess: async (_form) => {
          setLoading(true);
          const values = _form.values();
          const { place } = _form.$('investorProfile.address');
          values.investorProfile.address = place;
          const payload = deepCamelToSnakeCase(values);
          try {
            await api.postProfile(payload);
            try {
              await rootStore.fetchUser();
            } catch (e) {
              console.log(e);
            }
            navigate('/investor/profile');
          } catch (e) {
            handleSubmitError(_form, e);
          }
          setLoading(false);
        },
      },
    }),
    [profile, bankAccount],
  );

  useEffect(() => {
    if (edit || !profile?.investorProfile) return;

    navigate('/investor/profile');
  }, [profile]);

  if (loading) return <Loading />;

  return (
    <FormContext.Provider value={form}>
      <Box pl={['25px', '150px']} pt={['30px', '115px']}>
        <Heading size="3xl">Investor Profile</Heading>
        <Text fontSize="2xl" pt="15px" pb="30px">
          Please fill out this form to create an investment profile
        </Text>
        <Box
          border="1px"
          borderColor="gray.200"
          rounded="lg"
          pl="35px"
          w={['95%', '60%']}
        >
          <Box mb="6"> </Box>
          <Stack spacing={2}>
            <Box bg="primary.50" p="1" w="60%">
              <Icon pl="1" pr="2" boxSize={6}>
                <path
                  d="M20 22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13Z"
                  fill="#1328DD"
                />
              </Icon>
              Personal information
            </Box>
            <Field width={['80%', '60%']} field={form.$('firstName')} />
            <Field width={['80%', '60%']} field={form.$('middleName')} />
            <Field width={['80%', '60%']} field={form.$('lastName')} />
            <Field
              width={['80%', '60%']}
              field={form.$('phoneNumber')}
              mask="+1 (999) 999-9999"
            />
            <Field
              width={['80%', '60%']}
              field={form.$('ssn')}
              mask="999-99-9999"
            />

            <AddressField field={form.$('investorProfile.address')} />

            <Box bg="primary.50" p="1" w="60%">
              <Icon pl="1" pr="2" boxSize={6}>
                <path
                  d="M10.8998 2.09961L20.7988 3.51461L22.2128 13.4146L13.0208 22.6066C12.8333 22.7941 12.579 22.8994 12.3138 22.8994C12.0487 22.8994 11.7944 22.7941 11.6068 22.6066L1.70685 12.7066C1.51938 12.5191 1.41406 12.2648 1.41406 11.9996C1.41406 11.7344 1.51938 11.4801 1.70685 11.2926L10.8998 2.09961ZM13.7278 10.5856C13.9136 10.7713 14.1341 10.9186 14.3768 11.0191C14.6195 11.1195 14.8796 11.1712 15.1422 11.1712C15.4049 11.1711 15.6649 11.1193 15.9076 11.0188C16.1502 10.9182 16.3707 10.7709 16.5563 10.5851C16.742 10.3994 16.8893 10.1788 16.9898 9.93617C17.0903 9.69349 17.1419 9.43341 17.1419 9.17076C17.1419 8.90811 17.0901 8.64804 16.9895 8.4054C16.889 8.16276 16.7416 7.9423 16.5558 7.75661C16.3701 7.57092 16.1496 7.42364 15.9069 7.32317C15.6642 7.2227 15.4041 7.17101 15.1415 7.17106C14.611 7.17115 14.1024 7.38196 13.7273 7.75711C13.3523 8.13226 13.1417 8.64102 13.1418 9.17146C13.1419 9.70191 13.3527 10.2106 13.7278 10.5856Z"
                  fill="#1328DD"
                />
              </Icon>
              Bank account
            </Box>
            <Text>Your info is safe in our encrypted database</Text>
            {form.$('investorProfile.bankAccount').map((field) => (
              <Field
                width={['80%', '60%']}
                pt="15px"
                key={field.name}
                field={field}
              />
            ))}

            <HStack spacing="5" pb="5">
              <Box w="75%" />
              <Button type="button" onClick={form.onReset} mr={6} color="error">
                Reset
              </Button>

              <Button
                type="submit"
                color="white"
                bg="primary.500"
                onClick={form.onSubmit}
              >
                Submit
              </Button>
            </HStack>
          </Stack>
        </Box>
      </Box>
      <Observer>{() => form.error && <p>{form.error}</p>}</Observer>
    </FormContext.Provider>
  );
});
