import React, { useEffect } from 'react';
import useRootStore from 'hooks/use_root_store';
import SearchMap from 'components/shared/search_map';
import ListingsStore, {
  ListingsStoreProvider,
} from 'components/listings/stores/listings_store';
import Toolbar from 'components/listings/toolbar';
import ListItemContent from 'components/listings/list_item_content';
import SortButton from 'components/listings/sort_button';

export default () => {
  const { api } = useRootStore();
  const store = new ListingsStore(api);

  useEffect(() => store.getListingsOptions(), []);

  return (
    <ListingsStoreProvider value={store}>
      <SearchMap
        defaultCenter={{
          lat: 0,
          lng: 0,
        }}
        defaultZoom={8}
        mapStore={store}
        itemToMarkerProps={({ address: { latitude, longitude } }) => ({
          position: { lat: latitude, lng: longitude },
        })}
        ListItemComponent={ListItemContent}
        OnClickComponent={ListItemContent}
        Toolbar={Toolbar}
        SortButton={SortButton}
      />
    </ListingsStoreProvider>
  );
};
