import React, { useState } from 'react';
import {
  Box, GridItem, IconButton, SimpleGrid, Text,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

export default ({
  sensitiveText,
  maskedText,
  prefix = '',
}: {
  sensitiveText: string;
  maskedText: string;
  prefix?: string;
}) => {
  const [show, setShow] = useState(false);

  const displayText = show ? sensitiveText : maskedText;
  const toggleShow = () => setShow(!show);

  return (
    <SimpleGrid columns={1}>
      <GridItem position="relative">
        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
          <Text flex={1}>
            {`${prefix}${displayText}`}
            <IconButton
              onClick={toggleShow}
              aria-label="toggle ssn visibility"
              variant="ghost"
              icon={<Icon as={show ? MdVisibilityOff : MdVisibility} />}
            />
          </Text>
        </Box>
      </GridItem>
    </SimpleGrid>
  );
};
