import axios, { AxiosRequestConfig, Canceler } from 'axios';
import {
  deepCamelToSnakeCase,
  deepSnakeToCamelCase,
} from 'utils/string_conversion';
import AuthStore from 'stores/auth_store';

interface GetApiKeyResponse {
  apiKey: string;
}

export const getApiKey = async (): Promise<string> => {
  const csrfParam = document
    .querySelector('[name=csrf-param]')
    .getAttribute('content');
  const csrfToken = document
    .querySelector('[name=csrf-token]')
    .getAttribute('content');
  const { data } = await axios.post<GetApiKeyResponse>('/api_key', {
    [csrfParam]: csrfToken,
  });
  return deepSnakeToCamelCase<GetApiKeyResponse>(data).apiKey;
};

export interface User {
  id: number;
  email: string;
  profile?: Profile | null;
  bankAccount?: BankAccount | null;
}

export interface BankAccount {
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
}

export interface Address {
  street?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
  latitude: number;
  longitude: number;
  formattedAddress?: string | null;
}

export interface Profile {
  id: number;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  phoneNumber: string;
  ssn?: string | null;
  buyerProfile?: BuyerProfile | null;
  investorProfile?: InvestorProfile | null;
}

export interface BuyerProfile {
  id: number;
  profilePicUrl?: string | null;
  bio: string;
  currentLocation: Address;
  desiredLocation: Address;
  occupation: string;
  employer: string;
  fundraiseGoal: number;
  contributionsTotal?: number | null;
}

export interface InvestorProfile {
  id: number;
  address: Address;
  contributions?: Contribution[] | null;
}

export interface Contribution {
  amount: number;
  buyerProfile: BuyerProfile;
}

export interface Listing {
  id: number;
  propertyId: string;
  thumbnail?: string | null;
  propType: string;
  propStatus: string;
  price: number;
  beds?: number | null;
  baths?: number | null;
  lotSize?: number | null;
  lotSizeUnits?: string | null;
  buildingSize?: number | null;
  buildingSizeUnits?: string | null;
  address: Address;
  lastUpdate: string;
}

export interface ListingsOptions {
  propertyTypes: Array<string>;
}

export interface Features {
  [key: string]: boolean;
}

export default class Api {
  authStore: AuthStore;

  abortController = new AbortController();

  listingsCancel?: Canceler;

  constructor(_authStore: AuthStore) {
    this.authStore = _authStore;
  }

  getProfile = async (id?: number): Promise<Profile> => {
    const url = id ? `/api/v1/profiles/${id}` : '/api/v1/profile';
    const {
      data: { profile },
    } = await axios.get<{ profile: Profile }>(url, this.authedConfig);
    return deepSnakeToCamelCase(profile);
  };

  getUser = async (): Promise<User> => {
    const {
      data: { user },
    } = await axios.get<{ user: User }>('/api/v1/user', this.authedConfig);
    return deepSnakeToCamelCase(user);
  };

  getProfiles = async (
    bounds?: google.maps.LatLngBounds,
  ): Promise<Array<Profile>> => {
    const config = this.authedConfig;
    if (bounds) {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      config.params = { sw: [sw.lat(), sw.lng()], ne: [ne.lat(), ne.lng()] };
    }
    const {
      data: { profiles },
    } = await axios.get<{ profiles: Array<Profile> }>(
      '/api/v1/profiles',
      config,
    );
    return profiles.map((profile) => deepSnakeToCamelCase(profile));
  };

  getBuyerProfile = async (id: number) => {
    interface ResponseData {
      buyerProfile: BuyerProfile;
    }
    const { data } = await axios.get<ResponseData>(
      `/api/v1/buyer_profiles/${id}`,
      this.authedConfig,
    );
    return (deepSnakeToCamelCase(data) as ResponseData).buyerProfile;
  };

  getListings = async (
    bounds: google.maps.LatLngBounds,
    {
      minPrice,
      maxPrice,
      propertyTypes,
      beds,
      baths,
    }: {
      minPrice?: number;
      maxPrice?: number;
      propertyTypes?: string[];
      beds?: number;
      baths?: number;
    } = {},
  ): Promise<Array<Listing>> => {
    if (this.listingsCancel) {
      this.listingsCancel();
      this.listingsCancel = null;
    }
    const config = this.authedConfig;
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    config.params = { sw: [sw.lat(), sw.lng()], ne: [ne.lat(), ne.lng()] };
    if (minPrice) config.params.minPrice = minPrice;
    if (maxPrice) config.params.maxPrice = maxPrice;
    if (propertyTypes) config.params.propertyTypes = propertyTypes;
    if (beds) config.params.beds = beds;
    if (baths) config.params.baths = baths;
    config.params = deepCamelToSnakeCase(config.params);
    let canceler: Canceler;
    const {
      data: { listings },
    } = await axios.get<{ listings: Array<Listing> }>('/api/v1/listings', {
      ...config,
      cancelToken: new axios.CancelToken((c) => {
        this.listingsCancel = c;
        canceler = c;
      }),
    });

    if (canceler !== this.listingsCancel) {
      throw new Error('canceled request');
    }

    return listings.map((listing) => deepSnakeToCamelCase(listing));
  };

  getListingsOptions = async (): Promise<ListingsOptions> => {
    const {
      data: { options },
    } = await axios.get<{ options: ListingsOptions }>(
      '/api/v1/listings_options',
      this.authedConfig,
    );
    return deepSnakeToCamelCase(options);
  };

  postProfile = async (profile: object) => {
    await axios.post('/api/v1/profiles', { profile }, this.authedConfig);
  };

  postContribution = async (buyerProfileId: number, amount: number) => {
    await axios.post(
      '/api/v1/contributions',
      deepCamelToSnakeCase({ buyerProfileId, amount }),
      this.authedConfig,
    );
  };

  postLead = async (
    email: string,
    { source, userType }: { source?: string; userType?: string } = {},
  ) => {
    await axios.post(
      '/api/v1/leads',
      deepCamelToSnakeCase({ lead: { email, source, userType } }),
      this.authedConfig,
    );
  };

  getFeatures = async (): Promise<Features> => {
    const {
      data: { features },
    } = await axios.get<{ features: Features }>(
      '/api/v1/feature_toggles',
      this.authedConfig,
    );

    return features;
  };

  get authedConfig(): AxiosRequestConfig {
    return {
      headers: { authorization: `Token ${this.authStore?.apiKey}` },
    };
  }
}
