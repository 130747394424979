import React, { useMemo } from 'react';
import MobxReactForm from 'mobx-react-form';
import { Observer } from 'mobx-react';
import fields from 'components/home/forms/lead_form/fields';
import useRootStore from 'hooks/use_root_store';
import { createForm, FormContext, useForm } from 'forms/base_form';
import LeadFormStore, { useLeadFormStore } from 'stores/lead_form_store';
import {
  Box,
  Button,
  FormLabel,
  FormHelperText,
  FormControl,
  Input,
  HStack,
  Spacer,
  Text,
  useMediaQuery,
  Center,
} from '@chakra-ui/react';
import Loading from 'components/shared/loading';

interface FormLayoutProps {
  form: MobxReactForm;
}

const LargeLayout = ({ form }: FormLayoutProps) => (
  <Box pt={10} w={['90%', '90%', '90%', '90%', '80%']}>
    <Box p={3} borderWidth="1px" borderRadius="lg" bg="white">
      <HStack>
        <Observer>
          {() => (
            <FormControl isInvalid={Boolean(form.$('email').error)}>
              <Input
                size="lg"
                {...form.$('email').bind()}
                placeholder="Your email address..."
              />
            </FormControl>
          )}
        </Observer>
        <Spacer />
        <Button
          variant="primary"
          type="submit"
          width="45%"
          onClick={form.onSubmit}
        >
          Sign up for updates
        </Button>
      </HStack>
      <Observer>
        {() => form.error && (
        <Box p={3}>
          <Text>{form.error}</Text>
        </Box>
        )}
      </Observer>
    </Box>
  </Box>
);

const SmallLayout = ({ form }: FormLayoutProps) => (
  <Box pt={10} pb={10}>
    <Observer>
      {() => (
        <FormControl isInvalid={Boolean(form.$('email').error)}>
          <FormLabel>Learn more about Homeslice</FormLabel>
          <Input size="lg" {...form.$('email').bind()} />
          <FormHelperText>
            We&apos;ll never share your information
          </FormHelperText>
        </FormControl>
      )}
    </Observer>
    <Button variant="primary" type="submit" mt={4} onClick={form.onSubmit}>
      Sign up for updates
    </Button>
    <Observer>
      {() => form.error && (
      <Box p={3}>
        <Text>{form.error}</Text>
      </Box>
      )}
    </Observer>
  </Box>
);

const LeadForm = ({ userType }: { userType?: string }) => {
  const rootStore = useRootStore();
  const store = useLeadFormStore() ?? useMemo(() => new LeadFormStore(), [userType]);
  const form = useForm()
    ?? useMemo(
      () => createForm({
        fields: fields({ userType }),
        hooks: {
          onSuccess: store.submit,
        },
        options: {
          validateOnBlur: false,
        },
      }),
      [userType],
    );

  return (
    <Observer>
      {() => {
        const { user } = rootStore;
        const { completed, loading } = store;
        const [largeScreen] = useMediaQuery('(min-width: 62em)');
        if (user) {
          return null;
        }

        if (completed) {
          return (
            <Center>
              <Text>Thanks. We&apos;ll be in touch!</Text>
            </Center>
          );
        }

        if (loading) {
          return (
            <Box p={20}>
              <Loading noPositioning />
            </Box>
          );
        }

        const Layout = largeScreen ? LargeLayout : SmallLayout;
        return (
          <FormContext.Provider value={form}>
            <Layout form={form} />
          </FormContext.Provider>
        );
      }}
    </Observer>
  );
};

export default LeadForm;
