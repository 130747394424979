import React from 'react';
import imagePaths from 'utils/image_paths';
import InfoPageLayout from 'components/home/shared/info_page_layout';

const Investor = () => (
  <InfoPageLayout
    i18nPrefix="pages.home.investor"
    headerImage={imagePaths['house_angle.png']}
    sections={{
      diversify: ['contribute', 'stableInvestment'],
      passiveInvestment: ['getAPremium', 'buyerMaintains'],
      supportIndividuals: ['everyoneInvest', 'everyoneWins'],
    }}
    leadFormUserType="investor"
  />
);

export default Investor;
