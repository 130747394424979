import { makeObservable, observable, runInAction } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import Api from 'utils/api';
import { createContext, useContext } from 'react';

class LeadFormStore {
  api: Api;

  completed = false;

  loading = false;

  constructor() {
    this.api = new Api(null);
    makeObservable(this, {
      completed: observable,
      loading: observable,
    });
  }

  submit = (form: MobxReactForm) => {
    runInAction(() => {
      this.loading = true;
    });
    const {
      email,
      source,
      userType,
    }: { email: string; source?: string; userType?: string } = form.values();
    this.api
      .postLead(email, { source, userType })
      .then(() => runInAction(() => {
        this.completed = true;
      }))
      .catch((error) => {
        const {
          response: {
            data: { errors },
          },
        } = error;
        if (errors && errors.length > 0) {
          form.invalidate(errors.join(', '));
        } else {
          form.invalidate('An unknown error occurred');
        }
      })
      .finally(() => runInAction(() => {
        this.loading = false;
      }));
  };
}

export default LeadFormStore;

export const LeadFormStoreContext = createContext<LeadFormStore>(null);
export const useLeadFormStore = () => useContext(LeadFormStoreContext);
