import React from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  Show,
  SimpleGrid,
} from '@chakra-ui/react';
import { Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useRootStore from 'hooks/use_root_store';

export default () => {
  const { t } = useTranslation();

  const store = useRootStore();

  return (
    <Box pl={['25px', '150px']} pt={['70px', '120px']}>
      <Stack>
        <Observer>
          {() => {
            const name = store.user?.profile?.firstName;
            const email = store.user?.email ?? '';

            return <Heading size="2xl">{`Welcome, ${name || email}!`}</Heading>;
          }}
        </Observer>
        <Text pt="10px" fontSize="xl">
          Thank you for confirming your email. Let&apos;s get you closer to your
          goal.
        </Text>
      </Stack>
      <Box pt={['30px', '80px']}>
        <Text fontSize="3xl">Where do you want to start?</Text>
      </Box>
      <Box pt={['6', '30px']}>
        <Show above="md">
          <HStack spacing="50px">
            <Insides />
          </HStack>
        </Show>
        <Show below="lg">
          <SimpleGrid spacing={6}>
            <Insides />
          </SimpleGrid>
        </Show>
      </Box>
    </Box>
  );
};

const Insides = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        border="1px"
        borderColor="gray.200"
        width={['270px', '450px']}
        height="232px"
        pb={['100', 0]}
      >
        <Stack pl="25px" pt="25px">
          <Text fontSize="xl">Option 1</Text>
          <Box fontSize="2xl">Purchase a home</Box>
          <Box>Build my own dream with others&#39; help</Box>
          <Box pt={['15px', '30px']}>
            <Button
              colorScheme="primary"
              onClick={() => navigate('/homebuyer/signup')}
            >
              Start with purchasing
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box
        border="1px"
        borderColor="gray.200"
        width={['270px', '450px']}
        height="232px"
      >
        <Stack pl="25px" pt="25px">
          <Text fontSize="xl">Option 2</Text>
          <Box fontSize="2xl">Invest in real estate</Box>
          <Box>Invest in someone else&#39;s dream to own a home</Box>
          <Box pt={['15px', '30px']}>
            <Button
              colorScheme="primary"
              onClick={() => navigate('/investor/opportunities')}
            >
              Start with Investing
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
