import Api from 'utils/api';
import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';

class FundBuyerStore {
  api: Api;

  step: number;

  constructor(api: Api) {
    this.api = api;
    this.step = 0;

    makeObservable(this, {
      step: observable,
      setStep: action,
    });
  }

  setStep = (step: number) => {
    this.step = step;
  };
}

export default FundBuyerStore;

export const FundBuyerStoreContext = createContext<FundBuyerStore>(null);
export const useFundBuyerStore = () => useContext(FundBuyerStoreContext);
