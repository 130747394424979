import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import PrimaryButtonLink from 'components/shared/primary_button_link';
import { useTranslation } from 'react-i18next';
import { Family2Image } from 'components/home/landing/shared/images';

const Section2 = () => {
  const { t } = useTranslation();
  return (
    <Box h="718px" w="100%" pos="relative">
      <Box
        bgColor="primary.300"
        borderRadius="50%"
        filter="blur(300px)"
        h="406px"
        right={10}
        opacity={0.5}
        pos="absolute"
        top="156px"
        w="406px"
      />
      <Flex alignItems="center" gap="120px" w="100%">
        <Box h="718px" w="40%" ml="10%">
          <Family2Image />
        </Box>
        <Flex
          alignItems="flex-start"
          direction="column"
          gap="32px"
          pos="relative"
          mr="10%"
          ml="auto"
          w="30%"
        >
          <Text
            alignSelf="stretch"
            letterSpacing={0}
            lineHeight="32px"
            mt="-1px"
            fontSize="24px"
            fontStyle="normal"
            fontWeight={800}
          >
            {t('pages.landing.section2Title')}
          </Text>
          <Text
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
            alignSelf="stretch"
            letterSpacing={0}
            lineHeight="24px"
          >
            {t('pages.landing.section2Body')}
          </Text>
          <PrimaryButtonLink to="/company">
            {t('pages.landing.section2Button')}
          </PrimaryButtonLink>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Section2;
