import React from 'react';
import { Box, Container, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SubHeader from 'components/shared/sub_header';
import LeadForm from 'components/home/forms/lead_form';

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize={['20px', '36px']} fontWeight="800">
    {children}
  </Text>
);

const SectionText = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize={['16px', '24px']} fontWeight="400">
    {children}
  </Text>
);

const Section = ({
  i18nPrefix,
  headingKey,
  textKeys,
}: {
  i18nPrefix: string;
  headingKey: string;
  textKeys: string[];
}) => {
  const { t } = useTranslation();

  return (
    <Box pb={10}>
      <Box pb={[2, 4]}>
        <SectionHeading>{t(`${i18nPrefix}.${headingKey}`)}</SectionHeading>
      </Box>
      {textKeys.map((textKey, i) => (
        <Box key={textKey}>
          <SectionText>{t(`${i18nPrefix}.${textKey}`)}</SectionText>
          {i < textKeys.length - 1 && <Box p={[2, 4]} />}
        </Box>
      ))}
    </Box>
  );
};

const InfoPageLayout = ({
  i18nPrefix,
  headerImage,
  sections,
  leadFormUserType,
}: {
  i18nPrefix: string;
  headerImage: string;
  sections: { [_: string]: string[] };
  leadFormUserType?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box h={[10, 0]} />
      <Container maxW="100%" position="relative">
        <SubHeader
          title={t(`${i18nPrefix}.title`)}
          subtitle={t(`${i18nPrefix}.subtitle`)}
          image={headerImage}
        />
      </Container>
      <Container maxWidth={['320px', '928']} position="relative">
        <Box p={[0, 10]} pt={['40px', '20px']} />
        {Object.entries(sections).map(([headingKey, textKeys]) => (
          <Section
            key={headingKey}
            i18nPrefix={i18nPrefix}
            headingKey={headingKey}
            textKeys={textKeys}
          />
        ))}
        <LeadForm userType={leadFormUserType} />
        <Box h={40} />
      </Container>
    </Box>
  );
};

export default InfoPageLayout;
