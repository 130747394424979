import React from 'react';
import { Listing } from 'utils/api';
import {
  Center,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { MdHouse } from 'react-icons/md';
import imagePaths from 'utils/image_paths';

const buildSizeString = (
  title: string,
  size?: number,
  units?: string,
): string | null => {
  if (!size) return null;

  return `${title}: ${Math.round(size).toLocaleString('en-US')} ${units}`;
};

const ListItemContent = ({
  thumbnail,
  price,
  propType,
  propStatus,
  beds,
  baths,
  lotSize,
  lotSizeUnits,
  buildingSize,
  buildingSizeUnits,
  address: {
    street, city, state, zipCode,
  },
}: Listing) => (
  <SimpleGrid w="100%" columns={1} p={3} spacing={3}>
    <GridItem
      boxShadow="rgb(0 0 0 / 30%) 0px 2px 4px 0px"
      p={3}
      borderRadius={5}
    >
      <SimpleGrid columns={1} spacing={1}>
        <GridItem>
          <Center>
            {thumbnail ? (
              <Image
                maxW="100%"
                h={300}
                objectFit="contain"
                src={thumbnail}
                alt="property thumbnail"
                fallbackSrc={imagePaths['property-placeholder.jpg']}
              />
            ) : (
              <Icon
                as={MdHouse}
                h={300}
                maxW="100%"
                w="auto"
                color="neutral.200"
              />
            )}
          </Center>
        </GridItem>
        <GridItem>
          <SimpleGrid columns={1} spacing={1}>
            <GridItem>
              <Heading>
                {`$${Math.round(price).toLocaleString('en-US')}`}
              </Heading>
            </GridItem>
            <GridItem>
              <Text>
                {[street, city, state, zipCode].filter((s) => s).join(', ')}
              </Text>
            </GridItem>
            <GridItem>
              <Text>{`Status: ${propStatus.replace('_', ' ')}`}</Text>
            </GridItem>
            <GridItem>
              <Text>{propType.replace('_', ' ')}</Text>
            </GridItem>
            <GridItem>
              <Text>{`Beds: ${beds ?? 'N/A'} | Baths: ${baths ?? 'N/A'}`}</Text>
            </GridItem>
            <GridItem>
              {[
                buildSizeString('Building', buildingSize, buildingSizeUnits),
                buildSizeString('Lot', lotSize, lotSizeUnits),
              ]
                .filter((s) => s)
                .join(' | ')}
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </SimpleGrid>
    </GridItem>
  </SimpleGrid>
);

export default ListItemContent;
