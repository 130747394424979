import React, { MouseEventHandler } from 'react';
import {
  FormControl,
  FormHelperText,
  SimpleGrid,
  GridItem,
  Center,
  Box,
  Image,
} from '@chakra-ui/react';
import { action } from 'mobx';
import { observer, Observer } from 'mobx-react';
import { MdAdd, MdClose, MdSwapHorizontalCircle } from 'react-icons/md';
import { FieldProps } from 'components/shared/fields/field';
import { IconType } from 'react-icons';

const createPreview = (file) => {
  // eslint-disable-next-line
  file.preview = window.URL.createObjectURL(file);
  return file.preview;
};

const destroyPreview = (file, field) => (e) => {
  e.preventDefault();
  // eslint-disable-next-line
  window.URL.revokeObjectURL(file.preview);
  // eslint-disable-next-line
  const index = field.files.indexOf(file);
  action(() => field.files.splice(index, 1))();
};

export const getFileData = (file: Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result.toString());
  reader.onerror = reject;
  reader.readAsDataURL(file);
});

export interface FileFieldProps extends FieldProps {
  accept?: string;
}

const InputImage = ({
  src,
  alt,
  onClose,
  CloseIcon = MdClose,
}: {
  src: string;
  alt: string;
  onClose: MouseEventHandler;
  CloseIcon?: IconType;
}) => (
  <Box w={120} m="auto">
    <Image
      w="100%"
      objectFit="cover"
      borderRadius="50%"
      sx={{
        aspectRatio: '1/1',
      }}
      src={src}
      alt={alt}
    />
    <CloseIcon
      onClick={onClose}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: 16,
        height: 16,
        borderRadius: 8,
        background: 'black',
        color: 'white',
        cursor: 'pointer',
      }}
    />
  </Box>
);

const InputOrPreview = observer(({ field }: { field: any }) => {
  if (field.files && field.files.length > 0) {
    const f = field.files[0];
    return (
      <InputImage
        src={createPreview(f)}
        alt={f.name}
        onClose={destroyPreview(f, field)}
      />
    );
  }

  const url = field.value;
  if (url) {
    return (
      <InputImage
        src={url}
        alt={`profile picture: ${url}`}
        CloseIcon={MdSwapHorizontalCircle}
        onClose={() => document.getElementById(field.id).click()}
      />
    );
  }

  return (
    <SimpleGrid
      columns={1}
      borderRadius="50%"
      background="gray"
      w={120}
      textAlign="center"
      m="auto"
      color="white"
      sx={{
        aspectRatio: '1/1',
      }}
      onClick={() => document.getElementById(field.id).click()}
      alignContent="center"
      alignItems="center"
    >
      <GridItem>
        <Center>
          <span>{field.label}</span>
        </Center>
      </GridItem>
      <GridItem>
        <Center>
          <MdAdd />
        </Center>
      </GridItem>
    </SimpleGrid>
  );
});

export default ({
  field,
  inputProps = {},
  accept = null,
  ...props
}: FileFieldProps) => (
  <Observer>
    {() => (
      <FormControl
        isInvalid={Boolean(field.error)}
        required={field.$rules?.includes('required')}
        {...props}
      >
        <input
          style={{ display: 'none' }}
          {...field.bind()}
          value=""
          accept={accept}
          {...inputProps}
        />
        <InputOrPreview field={field} />
        <FormHelperText>{field.error}</FormHelperText>
      </FormControl>
    )}
  </Observer>
);
