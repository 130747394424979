import React from 'react';
import useRootStore from 'hooks/use_root_store';
import SearchMap from 'components/shared/search_map';
import BrowseStore from 'components/browse/stores/browse_store';
import ListItemContent from 'components/browse/list_item_content';

export default () => {
  const { api } = useRootStore();
  const browseStore = new BrowseStore(api);

  return (
    <SearchMap
      defaultCenter={{
        lat: 25.7616798,
        lng: -80.1917902,
      }}
      defaultZoom={8}
      mapStore={browseStore}
      itemToMarkerProps={({
        buyerProfile: {
          desiredLocation: { latitude, longitude },
        },
      }) => ({
        onClick: (e) => console.log(e),
        position: { lat: latitude, lng: longitude },
      })}
      ListItemComponent={ListItemContent}
    />
  );
};
