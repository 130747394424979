import React from 'react';
import { Link } from 'react-router-dom';
import { Text, TextProps } from '@chakra-ui/react';

const PrimaryButtonLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const defaultProps: TextProps = {
    bg: 'primary.500',
    borderRadius: 5,
    color: 'white',
    _hover: { textDecoration: 'none', bg: 'primary.600' },
    _active: { bg: 'primary.700' },
    paddingX: 4,
    paddingY: 2,
  };

  return (
    <Link to={to}>
      <Text fontWeight="bold" {...defaultProps}>
        {children}
      </Text>
    </Link>
  );
};

export default PrimaryButtonLink;
