import React, { useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import Field from 'components/shared/fields/field';

export default ({ field }: { field: any }) => {
  const autoComplete = useRef<google.maps.places.Autocomplete>(null);

  return (
    <Autocomplete
      onLoad={(ref) => {
        autoComplete.current = ref;
      }}
      onPlaceChanged={() => {
        const place = autoComplete.current.getPlace();
        // eslint-disable-next-line no-param-reassign
        field.place = place;
        field.set(place.formatted_address);
      }}
    >
      <Field field={field} />
    </Autocomplete>
  );
};
