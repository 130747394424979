import React, { useEffect, useState } from 'react';
import { useFundBuyerStore } from 'stores/investor/fund_buyer_store';
import {
  Button,
  Container,
  Heading,
  ModalFooter,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
} from '@chakra-ui/react';
import ProfileForm from 'components/investor/fund_buyer/profile_form';
import { useFundBuyerForm } from 'forms/investor/fund_buyer_form_context';
import Loading from 'components/shared/loading';

const ProfileCard = ({ profileIsValid }: { profileIsValid: boolean }) => {
  const form = useFundBuyerForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const wrappedOnClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Box display="inline-block">
      <Button onClick={onOpen}>
        {profileIsValid ? 'Review profile' : 'Complete profile'}
      </Button>
      <Modal isOpen={isOpen} onClose={wrappedOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <style>
              {/* this is so that google maps autocomplete shows up in modal */}
              {'.pac-container { z-index: 1900; }'}
            </style>
            <ProfileForm />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={wrappedOnClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default () => {
  const store = useFundBuyerStore();
  const form = useFundBuyerForm();
  const [loading, setLoading] = useState(true);
  const [profileValid, setProfileValid] = useState(false);
  useEffect(() => {
    if (!loading) return;

    form
      .validate()
      .then(({ isValid }) => setProfileValid(isValid))
      .finally(() => setLoading(false));
  }, [loading]);

  if (loading) return <Loading />;

  return (
    <Box>
      <Heading>Complete purchase</Heading>
      <Container p={10}>
        <Text display="inline-block" pr={5}>Profile information</Text>
        <ProfileCard profileIsValid={profileValid} />
      </Container>
      <Button onClick={() => store.setStep(2)}>
        <Text>Purchases slices</Text>
      </Button>
    </Box>
  );
};
