import React, {
  CSSProperties, useEffect, useMemo, useRef,
} from 'react';
import { Observer, observer } from 'mobx-react';
import {
  Identifiable,
  ItemToMarkerProps,
} from 'components/shared/search_map/types';
import { useMapStore } from 'components/shared/search_map/stores/map_store';
import debounce from 'utils/debounce';
import { Box, useTheme } from '@chakra-ui/react';
import {
  GoogleMap,
  MarkerClusterer,
  OverlayView,
} from '@react-google-maps/api';
import CustomMarker from 'components/shared/search_map/custom_marker';

const mapContainerStyle: CSSProperties = {
  width: '100%',
  height: '100%',
};

const Map = observer(
  <T extends Identifiable>({
    items,
    mapper,
    defaultCenter,
    defaultZoom,
    mapOptions,
    setItem,
  }: {
    items: T[];
    mapper: ItemToMarkerProps<T>;
    mapOptions: google.maps.MapOptions;
    defaultCenter: google.maps.LatLngLiteral;
    defaultZoom: number;
    setItem: (item: T) => void;
  }) => {
    const store = useMapStore<T>();
    const mapRef = useRef<google.maps.Map>(null);
    const center = mapRef.current?.getCenter() ?? defaultCenter;
    const zoom = mapRef.current?.getZoom() ?? defaultZoom;
    const zoomRef = useRef<number>(zoom);
    const boundsRef = useRef<google.maps.LatLngBounds>(null);
    const debouncedFetch = useMemo(
      () => debounce(() => {
        store.setBounds(mapRef.current.getBounds());
      }, 1000),
      [],
    );
    const {
      colors: {
        primary: { 500: primaryColor },
        secondary: { 300: secondaryColor },
        neutral: { 500: neutralColor },
      },
    } = useTheme();

    return (
      <div style={mapContainerStyle}>
        <Observer>
          {() => {
            const { place } = store;
            const map = mapRef.current;
            useEffect(() => {
              if (place && map) {
                map.fitBounds(place.geometry.viewport);
                store.place = null;
              }
            }, [place, map]);

            return null;
          }}
        </Observer>
        <GoogleMap
          options={mapOptions}
          zoom={zoom}
          center={center}
          mapContainerStyle={mapContainerStyle}
          onBoundsChanged={() => {
            if (!boundsRef.current) {
              boundsRef.current = mapRef.current.getBounds();
              debouncedFetch();
            }
          }}
          onLoad={(map) => {
            mapRef.current = map;
            const bounds = mapRef.current.getBounds();
            if (bounds) store.setBounds(bounds);
          }}
          onDragEnd={() => {
            debouncedFetch();
          }}
          onZoomChanged={() => {
            if (!mapRef.current) return;

            const currentZoom = mapRef.current.getZoom();
            if (currentZoom === zoomRef.current) return;

            zoomRef.current = currentZoom;
            if (store.place) return;

            debouncedFetch();
          }}
        >
          {!store.loading && (
            <MarkerClusterer>
              {(clusterer) => (
                <>
                  {items.map((item) => (
                    <CustomMarker
                      key={item.id}
                      clusterer={clusterer}
                      primaryColor={primaryColor}
                      secondaryColor={secondaryColor}
                      onClick={() => setItem(item)}
                      {...mapper(item)}
                    />
                  ))}
                </>
              )}
            </MarkerClusterer>
          )}
          <Observer>
            {() => (store.hoveredItem ? (
              <OverlayView
                mapPaneName="floatPane"
                position={mapper(store.hoveredItem).position}
              >
                <Box
                  w={30}
                  h={30}
                  backgroundColor={neutralColor}
                  borderRadius={15}
                  borderColor="white"
                  borderWidth={4}
                  ml={-3.5}
                  mt={-3.5}
                />
              </OverlayView>
            ) : null)}
          </Observer>
        </GoogleMap>
      </div>
    );
  },
);

export default Map;
