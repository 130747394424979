import { createContext, useContext } from 'react';
import Api, { Profile } from 'utils/api';
import { makeObservable, observable, runInAction } from 'mobx';

class ProfilesStore {
  api: Api;

  profiles: Profile[] = [];

  profile?: Profile | null;

  constructor(api: Api) {
    this.api = api;
    makeObservable(this, {
      profiles: observable.shallow,
      profile: observable,
    });
  }

  fetchAllProfiles = async () => {
    const profiles = await this.api.getProfiles();
    runInAction(() => {
      this.profiles = profiles;
    });
  };

  fetchProfile = async (id: number) => {
    let profile: Profile;
    try {
      profile = await this.api.getProfile(id);
    } catch (e) {
      console.log(e);
    }
    runInAction(() => {
      this.profile = profile;
    });
  };
}

export const ProfilesStoreContext = createContext<ProfilesStore>(null);
export const useProfilesStore = (): ProfilesStore => useContext(ProfilesStoreContext);

export default ProfilesStore;
