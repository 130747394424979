import React, { useEffect, useMemo, useState } from 'react';
import { Observer } from 'mobx-react';
import useRootStore from 'hooks/use_root_store';
import ProfilesStore from 'components/home_buyer/stores/profiles_store';
import Loading from 'components/shared/loading';
import {
  Box, Heading, GridItem, SimpleGrid, Text,
} from '@chakra-ui/react';
import ProfileCard from 'components/home_buyer/shared/profile_card';
import { useNavigate } from 'react-router-dom';

export default () => {
  const rootStore = useRootStore();
  const profilesStore = useMemo(() => new ProfilesStore(rootStore.api), []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;

    profilesStore.fetchAllProfiles().finally(() => setLoading(false));
  }, [loading]);

  if (loading) return <Loading absolute />;

  return (
    <SimpleGrid minChildWidth={300} spacing={1} p={3}>
      <Observer>
        {() => {
          const navigate = useNavigate();

          return (
            <Box p={12}>
              <Heading size="2xl">Investment Opportunities</Heading>
              <Text maxW="60%" fontSize="2xl" pt={8} pb={16}>
                {' '}
                This is a curated list of investment opportunities. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.
              </Text>
              {profilesStore.profiles.map((profile) => (
                <GridItem
                  key={profile.id}
                  onClick={() => navigate(`/homebuyer/profile/${profile.id}`)}
                >
                  <ProfileCard profile={profile} />
                </GridItem>
              ))}
            </Box>
          );
        }}
      </Observer>
    </SimpleGrid>
  );
};
