import React, { MouseEventHandler, useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { IconButton } from '@chakra-ui/react';
import { MdCancel } from 'react-icons/md';
import { Icon } from '@chakra-ui/icons';

const allFlashMessages = (flashMessages: FlashMessages): Array<string> => {
  let messages: Array<string> = [];
  Object.values(flashMessages).forEach((arr) => {
    messages = messages.concat(arr);
  });

  return messages;
};

type FlashMessages = { [key: string]: Array<string> };

const CloseButton = ({ onClick }: { onClick: MouseEventHandler }) => (
  <IconButton
    _hover={{
      background: 'blue.500',
    }}
    aria-label="close button"
    color="white"
    variant="ghost"
    onClick={onClick}
    colorScheme="blue"
    icon={<Icon as={MdCancel} />}
  />
);

const FlashToaster = ({
  flashMessages,
}: {
  flashMessages: FlashMessages;
}): null => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const closeAction = (key) => (
      <CloseButton onClick={() => closeSnackbar(key)} />
    );
    const options = {
      variant: 'info' as VariantType,
      action: closeAction,
    };
    allFlashMessages(flashMessages).forEach((message) => enqueueSnackbar(message, options));
  }, [flashMessages]);

  return null;
};

export default FlashToaster;
