import React from 'react';
import { Observer } from 'mobx-react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  Input,
  InputProps,
  FormLabel,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';

export interface FieldProps extends FormControlProps {
  field: any;
  inputProps?: InputProps;
  mask?: string;
  hideLabel?: boolean;
}

const Field = ({
  field,
  inputProps = {},
  mask,
  hideLabel,
  ...props
}: FieldProps) => (
  <Observer>
    {() => (
      <FormControl
        isInvalid={Boolean(field.error)}
        isRequired={field.$rules?.includes('required')}
        pb={field.error ? 0 : '24px'}
        {...props}
      >
        {!hideLabel && <FormLabel htmlFor={field.id}>{field.label}</FormLabel>}
        {mask ? (
          <InputMask {...field.bind()} mask={mask}>
            {() => <Input {...inputProps} />}
          </InputMask>
        ) : (
          <Input {...field.bind()} {...inputProps} />
        )}
        {field.error && <FormHelperText>{field.error}</FormHelperText>}
      </FormControl>
    )}
  </Observer>
);

export default Field;
