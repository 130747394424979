import React, { useMemo } from 'react';
import MobxReactForm from 'mobx-react-form';
import { Observer } from 'mobx-react';
import fields from 'components/home/forms/lead_form/fields';
import useRootStore from 'hooks/use_root_store';
import { createForm, FormContext, useForm } from 'forms/base_form';
import LeadFormStore, { useLeadFormStore } from 'stores/lead_form_store';
import {
  Box,
  Button,
  FormControl,
  Input,
  Text,
  useMediaQuery,
  Flex,
  Center,
} from '@chakra-ui/react';
import Loading from 'components/shared/loading';

interface FormLayoutProps {
  form: MobxReactForm;
}

const LargeLayout = ({ form }: FormLayoutProps) => (
  <Flex alignItems="center" gap="12px" left="20px" minW="541px" pos="relative">
    <Observer>
      {() => (
        // input
        <FormControl
          bgColor="white"
          h="48px"
          overflow="hidden"
          pos="relative"
          w="320px"
          isInvalid={Boolean(form.$('email').error)}
        >
          <Input size="lg" {...form.$('email').bind()} />
        </FormControl>
      )}
    </Observer>
    <Button
      bg="primary.500"
      color="white"
      type="submit"
      _hover={{ textDecoration: 'none', bg: 'primary.600' }}
      onClick={form.onSubmit}
    >
      Sign up for updates
    </Button>
    <Observer>
      {() => form.error && (
      <Box p={3}>
        <Text>{form.error}</Text>
      </Box>
      )}
    </Observer>
  </Flex>
);

const SmallLayout = ({ form }: FormLayoutProps) => (
  <Box pt={10} pb={10}>
    <Observer>
      {() => (
        <FormControl isInvalid={Boolean(form.$('email').error)}>
          <Input size="lg" {...form.$('email').bind()} />
        </FormControl>
      )}
    </Observer>
    <Button variant="primary" type="submit" mt={4} onClick={form.onSubmit}>
      Sign up for updates
    </Button>
    <Observer>
      {() => form.error && (
      <Box p={3}>
        <Text>{form.error}</Text>
      </Box>
      )}
    </Observer>
  </Box>
);

const LeadForm = ({ userType }: { userType?: string }) => {
  const rootStore = useRootStore();
  const store = useLeadFormStore() ?? useMemo(() => new LeadFormStore(), [userType]);
  const form = useForm()
    ?? useMemo(
      () => createForm({
        fields: fields({ userType }),
        hooks: {
          onSuccess: store.submit,
        },
        options: {
          validateOnBlur: false,
        },
      }),
      [userType],
    );

  return (
    <Observer>
      {() => {
        const { user } = rootStore;
        const { completed, loading } = store;
        const [largeScreen] = useMediaQuery('(min-width: 62em)');
        if (user) {
          return null;
        }

        if (completed) {
          return (
            <Center p={5}>
              <Text>Thanks. We&apos;ll be in touch!</Text>
            </Center>
          );
        }

        if (loading) {
          return (
            <Box p={20}>
              <Loading noPositioning />
            </Box>
          );
        }

        const Layout = largeScreen ? LargeLayout : SmallLayout;
        return (
          <FormContext.Provider value={form}>
            <Layout form={form} />
          </FormContext.Provider>
        );
      }}
    </Observer>
  );
};

export default LeadForm;
