export default {
  black: '#0f0f13',
  gray: {
    50: '#f9fafa',
    100: '#f1f1f2',
    200: '#e7e7e9',
    300: '#d3d4d7',
    400: '#abacb2',
    500: '#7d7f87',
    600: '#515460',
    700: '#343642',
    800: '#1e2026',
    900: '#18191f',
  },
  pink: {
    50: '#fff5f9',
    100: '#fdd7e6',
    200: '#fbb6d0',
    300: '#f985b2',
    400: '#f75d98',
    500: '#e32e74',
    600: '#c32864',
    700: '#9f2151',
    800: '#7d1940',
    900: '#5c132f',
  },
  orange: {
    50: '#fefaf3',
    100: '#fdebcf',
    200: '#fad395',
    300: '#f5ae3c',
    400: '#d7952c',
    500: '#b98026',
    600: '#9c6c20',
    700: '#7c5619',
    800: '#624414',
    900: '#503810',
  },
  yellow: {
    50: '#fffef8',
    100: '#fdfbd4',
    200: '#f8f174',
    300: '#eee430',
    400: '#d9d02c',
    500: '#b3ab24',
    600: '#8f891d',
    700: '#6f6b17',
    800: '#535011',
    900: '#45420e',
  },
  green: {
    50: '#f1fef9',
    100: '#abfbdc',
    200: '#31f2a8',
    300: '#2cd796',
    400: '#26bd83',
    500: '#21a270',
    600: '#1b865d',
    700: '#156848',
    800: '#11553b',
    900: '#0e4631',
  },
  cyan: {
    50: '#f3fbfe',
    100: '#cceffd',
    200: '#b4e7fb',
    300: '#98defa',
    400: '#3dc1f6',
    500: '#2fb2e5',
    600: '#2aa0cf',
    700: '#2384ab',
    800: '#1d6d8c',
    900: '#16546d',
  },
  blue: {
    50: '#f1f6fe',
    100: '#ccdffd',
    200: '#a7c7fb',
    300: '#7dadf9',
    400: '#5493f7',
    500: '#317bf1',
    600: '#2967c9',
    700: '#1f4f9a',
    800: '#1a407e',
    900: '#153567',
  },
  primary: {
    50: '#f9f6ff',
    100: '#e5dbfd',
    200: '#cfbcfc',
    300: '#b395fa',
    400: '#a27ff9',
    500: '#4734EB',
    600: '#7641f6',
    700: '#5d2cd6',
    800: '#4f25b5',
    900: '#3a1b84',
  },
  secondary: {
    50: '#fdf6ee',
    100: '#f9dcba',
    200: '#f3bb7a',
    300: '#df9641',
    400: '#c8863a',
    500: '#a87131',
    600: '#8e6029',
    700: '#724d21',
    800: '#60411c',
    900: '#452f14',
  },
  neutral: {
    50: '#f7f7f9',
    100: '#dee0e7',
    200: '#c2c5d2',
    300: '#a0a5b9',
    400: '#8d94ab',
    500: '#747c98',
    600: '#5f6889',
    700: '#495378',
    800: '#3b456d',
    900: '#25315b',
  },
};
