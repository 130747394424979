import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import 'i18n';
import { SnackbarProvider } from 'notistack';
import getAppProps from 'utils/get_app_props';
import RootStoreContext from 'contexts/root_store_context';
import RootStore from 'stores/root_store';
import FlashToaster from 'components/shared/flash_toaster';
import MainContent from 'components/main_content';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import Loading from 'components/shared/loading';
import theme from 'theme';
import ScrollToTop from 'components/shared/scroll_to_top';

Rails.start();
ActiveStorage.start();

const rootStore = new RootStore();

const App = ({
  flashMessages,
  googleApiKey,
}: {
  flashMessages: { [key: string]: Array<string> };
  googleApiKey: string;
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    rootStore.authStore
      .fetchApiKey()
      .then(() => rootStore.fetchUser())
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  rootStore.setGoogleApiKey(googleApiKey);

  return (
    <RootStoreContext.Provider value={rootStore}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <Box w="100vw" h="100vh">
              <ScrollToTop />
              <FlashToaster flashMessages={flashMessages} />
              {loading ? <Loading /> : <MainContent variant="fixed-header" />}
            </Box>
          </SnackbarProvider>
        </BrowserRouter>
      </ChakraProvider>
    </RootStoreContext.Provider>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const rootEl = document.createElement('div');
  document.body.appendChild(rootEl);
  const root = createRoot(rootEl);
  const { flashMessages, googleApiKey } = getAppProps();
  root.render(
    <App flashMessages={flashMessages} googleApiKey={googleApiKey} />,
  );
});
