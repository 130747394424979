import React, { ReactNode } from 'react';
import {
  Box, Container, Flex, Heading, Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PrimaryButtonLink from 'components/shared/primary_button_link';
import LeadFormV2 from 'components/home/forms/lead_form_v2';
import {
  Family1Image,
  Family2Image,
} from 'components/home/landing/shared/images';

const QuestionText = ({ children }: { children: ReactNode }) => (
  <Text
    letterSpacing={0}
    lineHeight="32px"
    textAlign="center"
    fontSize="24px"
    fontStyle="normal"
    fontWeight={800}
  >
    {children}
  </Text>
);

const Small = () => {
  const { t } = useTranslation();
  return (
    <Box w="100%">
      <Box p={10} pb={0}>
        <Heading as="h1" fontSize="60px" fontWeight={800} lineHeight="110%">
          {t('pages.landing.heading')}
        </Heading>
        <Text fontSize="20px" fontWeight="800" color="primary.600" pt="20px">
          {t('pages.landing.subheading')}
        </Text>
      </Box>
      <Box p={5}>
        <Box
          border="1px"
          borderRadius="5px"
          borderColor="neutral.100"
          px={5}
          boxShadow="0px 4px 6px -2px #0000000d, 0px 10px 15px -3px #0000001a"
        >
          <LeadFormV2 />
        </Box>
      </Box>
      <Box px={10} pt={10}>
        <Text
          fontSize="24px"
          fontStyle="normal"
          fontWeight={800}
          lineHeight="32px"
          pb={10}
        >
          {t('pages.landing.section1Title')}
        </Text>
        <Text
          fontSize="16px"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="24px"
          pb={10}
        >
          {t('pages.landing.section1Body')}
        </Text>
        <Flex>
          <PrimaryButtonLink to="/how">
            {t('pages.landing.section1Button')}
          </PrimaryButtonLink>
        </Flex>
      </Box>
      <Box w="100%" h="90vh" maxH={500} p={10}>
        <Family1Image />
      </Box>
      <Box px={10}>
        <Text
          fontSize="24px"
          fontStyle="normal"
          fontWeight={800}
          lineHeight="32px"
          pb={10}
        >
          {t('pages.landing.section2Title')}
        </Text>
        <Text
          fontSize="16px"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="24px"
          pb={10}
        >
          {t('pages.landing.section2Body')}
        </Text>
        <Flex>
          <PrimaryButtonLink to="/company">
            {t('pages.landing.section2Button')}
          </PrimaryButtonLink>
        </Flex>
      </Box>
      <Box w="100%" h="90vh" maxH={500} p={10}>
        <Family2Image />
      </Box>
      <Box w="100%" py={10} bgColor="primary.50">
        <Box w="100%" textAlign="center">
          <QuestionText>{t('pages.landing.bottomHeroQuestion1')}</QuestionText>
          <QuestionText>{t('pages.landing.bottomHeroQuestion2')}</QuestionText>
          <Text py={10} fontWeight={400} lineHeight="28px" textAlign="center">
            {t('pages.landing.followUs')}
          </Text>
        </Box>
        <Container
          bgColor="white"
          border="1px"
          borderColor="neutral.100"
          px={5}
          boxShadow="0px 4px 6px -2px #0000000d, 0px 10px 15px -3px #0000001a"
        >
          <LeadFormV2 />
        </Container>
      </Box>
    </Box>
  );
};

export default Small;
