import addressValidator from 'utils/validators/address_validator';
import { BankAccount, Profile } from 'utils/api';
import stripPhoneNumber from 'utils/profile/strip_phone_number';

export default (profile: Profile | null, bankAccount: BankAccount | null) => ({
  firstName: {
    label: 'First name',
    rules: 'required|string',
    value: profile?.firstName ?? '',
  },
  middleName: {
    label: 'Middle name',
    rules: 'string',
    value: profile?.middleName ?? '',
  },
  lastName: {
    label: 'Last name',
    rules: 'required|string',
    value: profile?.lastName ?? '',
  },
  ssn: {
    label: 'Social security number',
    placeholder: 'xxx-xx-xxxx',
    rules: ['required', 'string', 'size:9', 'regex:/^\\d+$/'],
    value: profile?.ssn ?? '',
    hooks: {
      onChange: (field) => field.set(field.value.replace(/\D/g, '')),
    },
  },
  phoneNumber: {
    label: 'Phone number',
    placeholder: '(999) 999-9999',
    rules: ['required', 'string', 'size:11', 'regex:/^\\d+$/'],
    value: stripPhoneNumber(profile?.phoneNumber) ?? '',
    hooks: {
      onChange: (field) => field.set(field.value.replace(/\D/g, '')),
    },
  },
  investorProfile: {
    fields: {
      address: {
        label: 'Current address',
        rules: 'required|string',
        value: profile?.investorProfile?.address?.formattedAddress ?? '',
        validators: [addressValidator],
      },
      bankAccount: {
        label: 'Bank account',
        fields: {
          name: {
            label: 'Name on account',
            rules: ['required', 'string'],
            value: bankAccount?.name ?? '',
          },
          accountNumber: {
            label: 'Account number',
            rules: ['required', 'numeric'],
            value: bankAccount?.accountNumber ?? '',
          },
          confirmAccountNumber: {
            label: 'Confirm account number',
            rules: [
              'required',
              'numeric',
              'same:investorProfile.bankAccount.accountNumber',
            ],
            value: bankAccount?.accountNumber ?? '',
          },
          routingNumber: {
            label: 'Routing number',
            rules: ['required', 'numeric'],
            value: bankAccount?.routingNumber ?? '',
          },
        },
      },
    },
  },
});
