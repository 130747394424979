import { IconType } from 'react-icons';
import {
  BackgroundProps, Box, ColorProps, Text,
} from '@chakra-ui/react';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@chakra-ui/icons';

const DrawerLink = ({
  icon,
  title,
  to,
  onClick,
}: {
  icon?: IconType;
  title: string;
  to: string;
  onClick: () => void;
}) => {
  const background: BackgroundProps['background'] = 'white';
  let color: ColorProps['color'] = 'neutral.600';
  let hover: CSSProperties = {
    background: 'neutral.300',
    color: 'white',
  };
  const active = to === window.location.pathname;
  if (active) {
    color = 'primary.600';
    hover = {};
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      style={{
        pointerEvents: !active ? undefined : 'none',
      }}
    >
      <Box
        _hover={hover}
        background={background}
        color={color}
        textDecoration="none"
        paddingY={3}
        fontWeight="bold"
      >
        {icon && (
          <Icon
            display="inline-block"
            as={icon}
            marginX={4}
            verticalAlign="middle"
          />
        )}
        <Text paddingX={icon ? undefined : 5} display="inline-block">
          {title}
        </Text>
      </Box>
    </Link>
  );
};

export default DrawerLink;
