import addressValidator from 'utils/validators/address_validator';
import { Profile } from 'utils/api';
import stripPhoneNumber from 'utils/profile/strip_phone_number';

export default (profile: Profile | null) => ({
  firstName: {
    label: 'First name',
    rules: 'required|string',
    value: profile?.firstName ?? '',
  },
  middleName: {
    label: 'Middle name',
    rules: 'string',
    value: profile?.middleName ?? '',
  },
  lastName: {
    label: 'Last name',
    rules: 'required|string',
    value: profile?.lastName ?? '',
  },
  phoneNumber: {
    label: 'Phone number',
    placeholder: '(999) 999-9999',
    rules: ['required', 'string', 'size:11', 'regex:/^\\d+$/'],
    value: stripPhoneNumber(profile?.phoneNumber) ?? '',
    hooks: {
      onChange: (field) => field.set(field.value.replace(/\D/g, '')),
    },
  },
  ssn: {
    label: 'Social security number',
    placeholder: 'xxx-xx-xxxx',
    rules: ['required', 'string', 'size:9', 'regex:/^\\d+$/'],
    value: profile?.ssn ?? '',
    hooks: {
      onChange: (field) => field.set(field.value.replace(/\D/g, '')),
    },
  },
  buyerProfile: {
    fields: {
      profilePic: {
        label: 'Profile Pic',
        type: 'file',
        value: profile?.buyerProfile?.profilePicUrl ?? '',
        validators: [
          ({ field: { value, files } }) => {
            if (value || (files && files.length > 0)) return true;

            return 'profile picture is required';
          },
        ],
      },
      bio: {
        label: 'Bio',
        placeholder: 'Why should someone invest in you?',
        rules: 'required|string',
        value: profile?.buyerProfile?.bio ?? '',
      },
      currentLocation: {
        label: 'Current location',
        placeholder: 'city, state',
        rules: 'required|string',
        value: profile?.buyerProfile?.currentLocation?.formattedAddress ?? '',
        validators: [addressValidator],
      },
      desiredLocation: {
        label: 'Desired location',
        placeholder: 'city, state',
        rules: 'required|string',
        value: profile?.buyerProfile?.desiredLocation?.formattedAddress ?? '',
        validators: [addressValidator],
      },
      occupation: {
        label: 'Occupation',
        value: profile?.buyerProfile?.occupation ?? '',
        rules: 'required|string',
      },
      employer: {
        label: 'Employer',
        value: profile?.buyerProfile?.employer ?? '',
        rules: 'required|string',
      },
      fundraiseGoal: {
        label: 'Fundraise goal',
        rules: 'required|numeric',
        value: profile?.buyerProfile?.fundraiseGoal ?? '',
      },
    },
  },
});
