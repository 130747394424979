import React, { useState } from 'react';
import { Marker, MarkerProps } from '@react-google-maps/api';

export interface CustomMarkerProps extends MarkerProps {
  primaryColor: string;
  secondaryColor: string;
}

const CustomMarker = ({
  primaryColor,
  secondaryColor,
  ...props
}: CustomMarkerProps) => {
  const [color, setColor] = useState(primaryColor);

  const svgMarker = {
    path: 'M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0',
    fillColor: color,
    fillOpacity: 0.9,
    strokeWeight: 3,
    strokeColor: 'white',
    scale: 0.5,
    anchor: new google.maps.Point(50, 50),
  };

  return (
    <Marker
      {...props}
      onMouseOver={() => setColor(secondaryColor)}
      onMouseOut={() => setColor(primaryColor)}
      icon={svgMarker}
    />
  );
};

export default CustomMarker;
