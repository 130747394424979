import React, { useMemo } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import LeadFormStore, { LeadFormStoreContext } from 'stores/lead_form_store';
import { createForm, FormContext } from 'forms/base_form';
import fields from 'components/home/forms/lead_form/fields';
import Large from 'components/home/landing/large';
import Small from 'components/home/landing/small';

const Landing = () => {
  const [largeScreen] = useMediaQuery('(min-width: 62em)');
  const leadFormStore = useMemo(() => new LeadFormStore(), []);
  const form = useMemo(
    () => createForm({
      fields: fields({}),
      hooks: {
        onSuccess: leadFormStore.submit,
      },
      options: {
        validateOnBlur: false,
      },
    }),
    [],
  );

  return (
    <FormContext.Provider value={form}>
      <LeadFormStoreContext.Provider value={leadFormStore}>
        {largeScreen ? <Large /> : <Small />}
      </LeadFormStoreContext.Provider>
    </FormContext.Provider>
  );
};

export default Landing;
