import React from 'react';
import imagePaths from 'utils/image_paths';
import OverlapImage from 'components/home/landing/shared/overlap_image';

export const Family1Image = () => (
  <OverlapImage
    src={imagePaths['landing-fam1.jpg']}
    alt="a family outside of a house"
    orientation="topLeft"
  />
);

export const Family2Image = () => (
  <OverlapImage
    src={imagePaths['landing-fam2.jpg']}
    alt="a happy family inside of a house"
    orientation="topRight"
  />
);
