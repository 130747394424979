import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import Api, { User } from 'utils/api';
import AuthStore from './auth_store';

class RootStore {
  authStore: AuthStore;

  googleApiKey: string;

  api: Api;

  user?: User;

  constructor() {
    this.authStore = new AuthStore();
    this.api = new Api(this.authStore);
    makeObservable(this, {
      authStore: observable,
      setGoogleApiKey: action,
      user: observable,
    });
  }

  setGoogleApiKey = (apiKey) => {
    this.googleApiKey = apiKey;
  };

  fetchUser = async () => {
    const user = await this.api.getUser();
    runInAction(() => {
      this.user = user;
    });
  };
}

export default RootStore;
