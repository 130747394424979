import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import Api, { Listing, ListingsOptions } from 'utils/api';
import { createContext, useContext } from 'react';
import { MapStoreBase } from 'components/shared/search_map/stores/map_store';

class ListingsStore extends MapStoreBase<Listing> {
  api: Api;

  minPrice?: number;

  maxPrice?: number;

  listingsOptions?: ListingsOptions;

  selectedPropertyTypes: string[] = observable.array([]);

  beds?: number;

  baths?: number;

  sortOptions = {
    price_asc: 'Price (low to high)',
    price_desc: 'Price (high to low)',
  };

  selectedSort?: string;

  constructor(api: Api) {
    super();
    this.api = api;
    makeObservable(this, {
      minPrice: observable,
      setMinPrice: action,
      maxPrice: observable,
      setMaxPrice: action,
      listingsOptions: observable.shallow,
      selectedPropertyTypes: observable.shallow,
      beds: observable,
      baths: observable,
      setBeds: action,
      setBaths: action,
      selectedSort: observable,
      setSelectedSort: action,
    });
  }

  get sortedItems() {
    const { items } = this;
    if (!this.selectedSort) return items;

    switch (this.selectedSort) {
      case 'price_asc':
        return items.slice().sort(({ price: a }, { price: b }) => a - b);
      case 'price_desc':
        return items.slice().sort(({ price: a }, { price: b }) => b - a);
      default:
        return items;
    }
  }

  setSelectedSort = (sort: string) => {
    this.selectedSort = sort;
  };

  setPlace = (place: google.maps.places.PlaceResult) => {
    this.setLoading(true);
    this.bounds = place.geometry.viewport;
    this.callListingsApi()
      .then((listings) => {
        runInAction(() => {
          this.items = listings;
          this.place = place;
        });
      })
      .catch((e) => console.log(e))
      .finally(() => this.setLoading(false));
  };

  setBounds = (bounds: google.maps.LatLngBounds) => {
    this.setLoading(true);
    this.bounds = bounds;
    this.callListingsApi()
      .then((listings) => this.setItems(listings))
      .catch((e) => console.log(e))
      .finally(() => this.setLoading(false));
  };

  refresh = () => {
    if (!this.bounds) return;

    this.setLoading(true);
    this.callListingsApi()
      .then((listings) => this.setItems(listings))
      .catch((e) => console.log(e))
      .finally(() => this.setLoading(false));
  };

  getListingsOptions = () => {
    this.api
      .getListingsOptions()
      .then((options) => {
        runInAction(() => {
          this.listingsOptions = options;
        });
      })
      .catch((e) => console.log(e));
  };

  setMinPrice = (price: number) => {
    this.minPrice = price;
  };

  setMaxPrice = (price: number) => {
    this.maxPrice = price;
  };

  setBaths = (i: number | null) => {
    this.baths = i;
  };

  setBeds = (i: number | null) => {
    this.beds = i;
  };

  togglePropertyType = (propType: string) => {
    const idx = this.selectedPropertyTypes.indexOf(propType);
    runInAction(() => {
      if (idx >= 0) {
        this.selectedPropertyTypes.splice(idx, 1);
      } else {
        this.selectedPropertyTypes.push(propType);
      }
    });
  };

  callListingsApi = (): Promise<Listing[]> => this.api.getListings(this.bounds, {
    minPrice: this.minPrice,
    maxPrice: this.maxPrice,
    propertyTypes: this.selectedPropertyTypes,
    beds: this.beds,
    baths: this.baths,
  });
}

const ListingsStoreContext = createContext<ListingsStore>(null);

export const ListingsStoreProvider = ListingsStoreContext.Provider;

export const useListingsStore = (): ListingsStore => useContext(ListingsStoreContext);

export default ListingsStore;
