import React from 'react';
import {
  LinkProps, Link, Button, ButtonProps,
} from '@chakra-ui/react';

export default ({ href, ...props }: LinkProps & ButtonProps) => {
  const defaultProps: LinkProps & ButtonProps = {
    bg: 'primary.800',
    borderRadius: 5,
    color: 'white',
    _hover: { textDecoration: 'none', bg: 'primary.700' },
    _active: { bg: 'primary.900' },
    paddingX: 4,
    paddingY: 2,
    fontWeight: 'bold',
  };

  if (props['data-method']) {
    const csrfParam = document
      .querySelector('[name=csrf-param]')
      .getAttribute('content');
    const csrfToken = document
      .querySelector('[name=csrf-token]')
      .getAttribute('content');

    return (
      <form action={href} method="post">
        <input name={csrfParam} value={csrfToken} hidden />
        <input name="_method" value={props['data-method']} hidden />
        <Button {...defaultProps} {...props} type="submit" />
      </form>
    );
  }

  return <Link {...defaultProps} {...props} href={href} />;
};
