import React from 'react';
import { Menu, MenuProps } from '@chakra-ui/react';

interface CustomMenuProps extends MenuProps {
  onClose: () => void;
}

const CustomMenu = ({ children, ...props }: CustomMenuProps) => (
  <Menu colorScheme="primary" autoSelect={false} {...props}>
    {children}
  </Menu>
);

export default CustomMenu;
