export default ({
  source,
  userType,
}: {
  source?: string;
  userType?: string;
}) => ({
  email: {
    label: 'email',
    placeholder: 'you@email.com',
    rules: 'required|email',
    hooks: {
      onChange: (field) => field.set(field.value?.replace(/\s+/, '')),
    },
  },
  source: {
    rules: 'string',
    value: source,
  },
  userType: {
    rules: 'string',
    value: userType,
  },
});
