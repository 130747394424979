import React from 'react';
import { Box, Center, useColorModeValue } from '@chakra-ui/react';

const Footer = () => (
  <Box
    h="78px"
    bg={useColorModeValue('gray.50', 'purple.900')}
    color={useColorModeValue('black', 'purple.900')}
  >
    <Center p="25px">
      {`🏡 Homeslice, ${new Date().getFullYear()}`}
    </Center>
  </Box>
);

export default Footer;
