import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          appBar: {
            siteName: 'Homeslice',
            logIn: 'Log in',
            logOut: 'Log out',
          },
          pages: {
            landing: {
              heading: 'Creating a generation of first time home buyers',
              subheading:
                'Homeslice crowdfunds down payments for first time homebuyers in exchange for a slice of home equity',
              section1Title: "We're with you from fundraising to close",
              section1Body:
                "Whether you're a homebuyer looking for your first home, or an "
                + "investor looking for an altruistic opportunity, Homeslice's "
                + 'process makes real estate ownership more accessible.',
              section1Button: 'How if works',
              section2Title:
                "We're focused on creating thriving local communities",
              section2Body:
                'We believe the key to successful communities is to have its essential '
                + 'workers and locals living within it. From teachers to police officers, '
                + 'communities are stronger when their inhabitants can truly call them home.',
              section2Button: 'Our mission',
              bottomHeroQuestion1: 'Looking for your first home?',
              bottomHeroQuestion2:
                'Looking for an investment opportunity that does good?',
              followUs: 'Follow us as we get ready for launch in 2023.',
            },
            goals: {
              welcome: 'Welcome,',
              pleaseShare:
                'Please share some information with us so we can best serve you.',
              question: 'Primary reason for being here:',
              buy: "Building my own dream with others' help",
              invest: "Invest in someone else's dream to own a home",
            },
            home: {
              buyer: {
                title: 'Homeslice is housing for the people',
                subtitle:
                  'We crowdfund down payments for first time home buyers in exchange for a slice of home equity.',
                helpsYouBuy:
                  "Homeslice helps you buy a home even if you don't have a ton of savings.",
                downPaymentProblem:
                  "You pay a lot for rent each month, but it's hard to save 20% down for a mortgage. "
                  + 'Homeslice provides most of the down payment so you can move into your own home quickly '
                  + '-- without missing out on life for years while you save.',
                downPaymentSolution:
                  'Homeslice solves the down payment for you, so you can spend less time '
                  + 'saving up a nest egg, and more time nesting in your own home.',
                stableHousingCosts:
                  'Enjoy stable housing costs, rather than unpredictable rent increases.',
                landlords:
                  'Landlords talk about how renting allows you to “be flexible,” but they '
                  + 'ignore the harsh reality of the market: rental listings in Asheville '
                  + "went up 25% last year. With increases like that, it's nearly impossible to save anything.",
                fixedMortgage:
                  "Homeslice gets you a fixed rate mortgage so your payments won't "
                  + 'skyrocket each year. So you can be flexible about other stuff -- like who picks '
                  + 'what movie to watch.',
                payYourOwnMortgage:
                  "You pay your own mortgage -- not your landlord's -- and build "
                  + 'valuable equity in the process.',
                payingOtherMortgage:
                  "It's a basic truth of renting: your rent pays someone else's "
                  + 'mortgage. Every. Single. Month. And you have nothing to show for it '
                  + 'but a place to live.',
                buildEquity:
                  'With Homeslice, paying your mortgage builds valuable equity for '
                  + "yourself. By making those monthly payments, you'll be on your way "
                  + 'to owning the most valuable asset of your life and creating '
                  + 'generational wealth in the process.',
              },
              company: {
                title: 'Homeslice is here to support communities',
                subtitle:
                  'We crowdfund down payments for first time home buyers in exchange for a slice of home equity.',
                americanDream:
                  "Home ownership is the foundation of the American Dream -- and it's at risk.",
                owningHome:
                  'Owning a home has always been the best way for families to create '
                  + 'generational wealth, but home ownership rates are falling and the trends '
                  + "don't look good.",
                greedyCorporations:
                  'The share of first time buyers purchases fell dramatically in 2022, with '
                  + 'buyers squeezed out by greedy corporations. If millions of families lose '
                  + 'access to their best wealth building tool, the middle class will shrink '
                  + 'while the rich get richer.',
                ourMission:
                  'Our mission is to create a new generation of first time homebuyers -- '
                  + 'not more rentals and Airbnbs.',
                communitiesThrive:
                  'Communities thrive when their members are safe, stable, and connected. '
                  + 'Unlike other real estate investments, Homeslice exclusively supports '
                  + 'first time buyers that form the foundation of healthy communities.',
                fosterCommunities:
                  'We believe the rapid expansion of investors running rentals and Airbnbs '
                  + 'is a good way to make a quick buck, but a terrible way to foster '
                  + 'healthy, tight-knit communities.',
                noCutForHomeslice:
                  "Homeslice doesn't take a cut of any homes -- only buyers and "
                  + 'investors do.',
                mostTakeCuts:
                  'Fundamentally, any profit a proptech makes off of home purchases is '
                  + "equity buyers and investors won't receive. Unlike other real estate "
                  + "platforms and proptech companies, Homeslice doesn't take a cut of "
                  + 'any purchases.',
                ourBeliefs:
                  'We believe through technology, new transaction models and participation '
                  + "in the massive US real estate market, there's room to build a "
                  + 'fantastic business whose interests are completely aligned with its '
                  + 'customers and the community.',
              },
              how: {
                title: 'Homeslice is when people meet proptech',
                subtitle:
                  'We crowdfund down payments for first time home buyers in exchange for a slice of home equity.',
                crowdfunds: 'Homeslice crowdfunds most of the down payment.',
                communityInvestors:
                  'Community investors put up most of the cash so a first time buyer can '
                  + 'make a 20% down payment. This allows a buyer to buy in a competitive '
                  + 'market and get a good mortgage.',
                investorsReceive: 'Investors receive a slice of home equity.',
                exchangeEquity:
                  'In exchange for putting up cash when the home is purchased, investors '
                  + 'get an equity stake in the home -- a homeslice. That homeslice turns '
                  + 'into cash when the owner buys it back or sells their home.',
                buyersMortgage:
                  'Buyers pay the mortgage and maintain the home.',
                buyersLiveInHome:
                  'The buyer lives in the home and pays the mortgage, building equity in '
                  + 'the process. Investors are kept up to date so they can see the family '
                  + 'they helped out and know their investment is in good shape.',
                buyersPackBack: 'With time, buyers pay back investors.',
                timeFrame:
                  'After 15 years, buyers can either pay back investors outright, refinance '
                  + 'their mortgage or sell the home to complete the relationship. Whichever '
                  + 'way the buyer goes, the equity and home appreciation will create a '
                  + 'win-win scenario for everyone.',
              },
              investor: {
                title: 'Homeslice is real estate for the people',
                subtitle:
                  'We crowdfund down payments for first time home buyers in exchange for a slice of home equity.',
                diversify:
                  'Diversify your portfolio with a slice of real estate.',
                contribute:
                  'You contribute to the down payment for a first time buyer and receive a '
                  + 'slice of home equity -- a homeslice. Your slice turns into cash when the '
                  + 'buyer purchases it back from you or sells their home.',
                stableInvestment:
                  'Real estate is a stable investment, with proven returns over time. '
                  + 'Homeslice helps you invest in a real property asset that carries real '
                  + 'world value that you can actually understand. It’s like the opposite of '
                  + 'your crypto.',
                passiveInvestment:
                  'A passive investment with a 50% premium off the bat.',
                getAPremium:
                  'If you contribute 1% of the home’s price to the buyer’s down payment, '
                  + 'you receive a 1.5% slice of home equity. That’s a huge premium for an '
                  + 'asset backed investment.',
                buyerMaintains:
                  'The buyer lives in, maintains and pays the entire mortgage for the home. '
                  + 'And you know your investment is well taken care of, because the buyer '
                  + 'wants the same thing you do: their home to be worth as much as possible.',
                supportIndividuals:
                  'Homeslice supports individual home ownership -- not more landlords.',
                everyoneInvest:
                  'Everybody says to invest in real estate, but you don’t want to become a '
                  + "landlord. Unlike other real estate platforms, Homeslice doesn't make "
                  + 'more rentals -- Homeslice helps communities thrive by creating more '
                  + 'first time buyers.',
                everyoneWins:
                  'Homeslice lets you invest in real estate, strengthen the community, and '
                  + 'help a first time buyer all at the same time. With Homeslice, everyone wins.',
              },
            },
          },
        },
      },
    },
  })
  .catch((error) => console.log('i18n error:', error)); // eslint-disable-line no-console

export default i18n;
