import { deepSnakeToCamelCase } from 'utils/string_conversion';

interface FlashMessages {
  [key: string]: Array<string>;
}

export interface AppProps {
  flashMessages: FlashMessages;
  googleApiKey: string;
}

interface Window {
  [key: string]: any;
}

const getAppProps = (): AppProps => {
  const props = (window as Window).appProps || {};
  delete (window as Window).appProps;
  const dataScript = document.getElementById('react-app-props');
  dataScript.remove();
  return deepSnakeToCamelCase(props);
};

export default getAppProps;
