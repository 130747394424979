import React from 'react';
import { Route } from 'react-router-dom';
import Profiles from './profiles';
import Signup from './signup';
import Profile from './profile';

export default (
  <>
    <Route path="signup" element={<Signup />} />
    <Route path="profiles" element={<Profiles />} />
    <Route path="profile/edit" element={<Signup edit />} />
    <Route path="profile/:id" element={<Profile />} />
    <Route path="profile" element={<Profile />} />
  </>
);
