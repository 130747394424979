import React, { useMemo } from 'react';
import {
  Box, Container, GridItem, SimpleGrid, Text,
} from '@chakra-ui/react';
import { MobxReactForm } from 'mobx-react-form';
import { createForm, handleSubmitError } from 'forms/base_form';
import fundBuyerFields from 'forms/investor/fund_buyer_fields';
import { FundBuyerFormContext } from 'forms/investor/fund_buyer_form_context';
import FundingAmount from 'components/investor/fund_buyer/funding_amount';
import useRootStore from 'hooks/use_root_store';
import FundBuyerStore, {
  FundBuyerStoreContext,
  useFundBuyerStore,
} from 'stores/investor/fund_buyer_store';
import FundingSuccess from 'components/investor/fund_buyer/funding_success';
import FundingRequirements from 'components/investor/fund_buyer/funding_requirements';
import { Observer } from 'mobx-react';
import { deepCamelToSnakeCase } from 'utils/string_conversion';

const StepCrumb = ({
  description,
  index,
  currentIndex,
}: {
  description: string;
  index: number;
  currentIndex: number;
}) => {
  const isSelected = index === currentIndex;
  const complete = index < currentIndex;
  const store = useFundBuyerStore();

  let color = 'neutral.200';
  if (isSelected) color = 'primary.600';
  else if (complete) color = 'neutral.600';

  return (
    <Container
      onClick={() => {
        if (!complete) return;

        store.setStep(index);
      }}
    >
      <Box h={5} w="100%" bg={color} />
      <Text>
        {`Step ${index + 1}`}
        {complete && ' | Complete'}
      </Text>
      <Text>{description}</Text>
    </Container>
  );
};

export default () => {
  const rootStore = useRootStore();
  const {
    api,
    user: { profile, bankAccount },
  } = rootStore;
  const form = useMemo<MobxReactForm>(
    () => createForm({
      fields: fundBuyerFields({ profile, bankAccount }),
      hooks: {
        onSuccess: async (_form) => {
          const values = _form.values();
          const { place } = _form.$('investorProfile.address');
          values.investorProfile.address = place;
          const payload = deepCamelToSnakeCase(values);
          try {
            await api.postProfile(payload);
            try {
              await rootStore.fetchUser();
            } catch (e) {
              console.log(e);
            }
          } catch (e) {
            handleSubmitError(_form, e);
          }
        },
      },
    }),
    [profile, bankAccount],
  );
  const store = useMemo<FundBuyerStore>(() => new FundBuyerStore(api), [api]);
  const steps = [
    {
      Component: FundingAmount,
      description: 'Select amount',
    },
    {
      Component: FundingRequirements,
      description: 'Sign documents',
    },
    {
      Component: FundingSuccess,
      description: 'Confirmation',
    },
  ];

  return (
    <FundBuyerStoreContext.Provider value={store}>
      <FundBuyerFormContext.Provider value={form}>
        <Box w="100%" p={5}>
          <Observer>
            {() => {
              const currentStep = store.step;

              return (
                <SimpleGrid columns={3} px={10} spacingX={10}>
                  {steps.map(({ description }, i) => (
                    <GridItem key={description}>
                      <StepCrumb
                        description={description}
                        index={i}
                        currentIndex={currentStep}
                      />
                    </GridItem>
                  ))}
                </SimpleGrid>
              );
            }}
          </Observer>
          <Observer>
            {() => {
              const { Component } = steps[store.step];

              return (
                <Box p={10}>
                  <Component />
                </Box>
              );
            }}
          </Observer>
        </Box>
      </FundBuyerFormContext.Provider>
    </FundBuyerStoreContext.Provider>
  );
};
