import React, { useRef } from 'react';
import { useMapStore } from 'components/shared/search_map/stores/map_store';
import { Box, Input } from '@chakra-ui/react';
import { Autocomplete } from '@react-google-maps/api';

const SearchBar = () => {
  const store = useMapStore();
  const autoComplete = useRef<google.maps.places.Autocomplete>(null);

  return (
    <Box w="100%">
      <Autocomplete
        onLoad={(ref) => {
          autoComplete.current = ref;
        }}
        onPlaceChanged={() => {
          store.setPlace(autoComplete.current.getPlace());
        }}
      >
        <Input type="text" />
      </Autocomplete>
    </Box>
  );
};

export default SearchBar;
