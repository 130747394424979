import { extendTheme } from '@chakra-ui/react';
import colors from 'theme/colors';
import shadows from 'theme/shadows';
import fonts from 'theme/fonts';
import components from 'theme/components';

export default extendTheme({
  colors,
  shadows,
  fonts,
  components,
});
