import React from 'react';
import { Observer } from 'mobx-react';
import { FormControl, Textarea, FormHelperText } from '@chakra-ui/react';
import { FieldProps } from 'components/shared/fields/field';

interface TextAreaProps extends FieldProps {
  rows?: number;
}

export default ({
  field,
  rows = 4,
  inputProps = {},
  ...props
}: TextAreaProps) => (
  <Observer>
    {() => (
      <FormControl
        isInvalid={Boolean(field.error)}
        isRequired={field.$rules?.includes('required')}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Textarea rows={rows} {...field.bind()} {...inputProps} />
        <FormHelperText>{field.error}</FormHelperText>
      </FormControl>
    )}
  </Observer>
);
