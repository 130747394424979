import React from 'react';
import { useFundBuyerForm } from 'forms/investor/fund_buyer_form_context';
import {
  Button,
  Checkbox,
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Observer } from 'mobx-react';
import { useFundBuyerStore } from 'stores/investor/fund_buyer_store';

export default () => {
  const store = useFundBuyerStore();
  const form = useFundBuyerForm();

  return (
    <Box>
      <Heading>Select amount</Heading>
      <Observer>
        {() => (
          <SimpleGrid pt={5} columns={3} spacing={5}>
            {[100, 500, 1000, 5000].map((amount) => (
              <GridItem
                key={amount}
                onClick={() => form.$('amount').set(amount)}
              >
                <Button disabled={form.$('amount').value === amount} w="100%">
                  <Text>{`$${amount}`}</Text>
                </Button>
              </GridItem>
            ))}
          </SimpleGrid>
        )}
      </Observer>
      <Observer>
        {() => (
          <Checkbox
            p={10}
            isChecked={form.$('acceptedDisclaimer').value}
            onChange={({ target: { checked } }) => form.$('acceptedDisclaimer').set(checked)}
          >
            <Text>
              I understand I am investing with the intention of holding my
              securities for at least 6 months, and that Homeslice will not
              offer refunds on my investment. Please review the investment
              details before finalizing the purchase.
            </Text>
          </Checkbox>
        )}
      </Observer>
      <Observer>
        {() => {
          const acceptedDisclaimer = form.$('acceptedDisclaimer').value;
          const amount = form.$('amount').value;
          const enabled = amount && acceptedDisclaimer;

          return (
            <Button disabled={!enabled} onClick={() => store.setStep(1)} m={10}>
              <Text>Next</Text>
            </Button>
          );
        }}
      </Observer>
    </Box>
  );
};
