import React from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <Heading>The smiths</Heading>
      <Heading>About us</Heading>
      <Button onClick={() => navigate('invest')}>Invest</Button>
    </Box>
  );
};
