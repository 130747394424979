import { runInAction } from 'mobx';
import Api, { Profile } from 'utils/api';
import { createContext, useContext } from 'react';
import { MapStoreBase } from 'components/shared/search_map/stores/map_store';

class BrowseStore extends MapStoreBase<Profile> {
  api: Api;

  constructor(api: Api) {
    super();
    this.api = api;
  }

  setPlace = (place: google.maps.places.PlaceResult) => {
    this.api
      .getProfiles(place.geometry.viewport)
      .then((profiles) => {
        runInAction(() => {
          this.items = profiles;
          this.place = place;
        });
      })
      .catch((e) => console.log(e));
  };

  setBounds = (bounds: google.maps.LatLngBounds) => {
    this.api
      .getProfiles(bounds)
      .then((profiles) => this.setItems(profiles))
      .catch((e) => console.log(e));
  };
}

export const BrowseStoreContext = createContext<BrowseStore>(null);
export const useBrowseStore = (): BrowseStore => useContext(BrowseStoreContext);

export default BrowseStore;
