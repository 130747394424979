export default {
  variants: {
    primary: () => ({
      bg: 'primary.800',
      color: 'white',
      fontWeight: 'bold',
      _hover: {
        bg: 'primary.700',
        color: 'white',
        _disabled: {
          bg: 'primary.500',
          color: 'white',
        },
      },
      _active: {
        bg: 'primary.900',
        color: 'white',
      },
    }),
    secondary: () => ({
      bg: 'secondary.300',
      color: 'white',
      _hover: {
        bg: 'secondary.400',
        color: 'white',
        _disabled: {
          bg: 'secondary.300',
          color: 'white',
        },
      },
      _active: {
        bg: 'secondary.500',
        color: 'white',
      },
    }),
    neutral: () => ({
      bg: 'neutral.500',
      color: 'white',
      _hover: {
        bg: 'neutral.600',
        color: 'white',
        _disabled: {
          bg: 'neutral.500',
          color: 'white',
        },
      },
      _active: {
        bg: 'neutral.700',
        color: 'white',
      },
    }),
  },
};
