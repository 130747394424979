import { makeObservable, observable, action } from 'mobx';
import { getApiKey } from 'utils/api';

class AuthStore {
  apiKey?: string;

  constructor() {
    makeObservable(this, {
      apiKey: observable,
      setApiKey: action,
    });
  }

  fetchApiKey = async (): Promise<void> => {
    this.setApiKey(await getApiKey());
  };

  setApiKey = (apiKey?: string): void => {
    this.apiKey = apiKey;
  };
}

export default AuthStore;
