import React, { useRef } from 'react';
import { Observer } from 'mobx-react';
import useRootStore from 'hooks/use_root_store';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  SimpleGrid,
  GridItem,
  Image,
  PositionProps,
  EffectProps,
  useMediaQuery,
  DrawerFooter,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { MdTravelExplore, MdHome } from 'react-icons/md';
import FullLogo from 'images/logo_full.svg';
import NavBarButton from 'components/shared/app_bar/nav_bar_button';
import DrawerLink from 'components/shared/app_bar/drawer_link';
import NavBarLink from 'components/shared/app_bar/nav_bar_link';
import FeatureGate from 'components/shared/feature_gate';

export default ({
  position = 'relative',
  shadow,
}: {
  position?: PositionProps['position'];
  shadow?: EffectProps['shadow'];
}) => {
  const rootStore = useRootStore();
  const btnRef = useRef(null);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargeScreen] = useMediaQuery('(min-width: 50em)');

  return (
    <Observer>
      {() => {
        const {
          authStore: { apiKey },
        } = rootStore;
        const authorized = Boolean(apiKey);

        return (
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            paddingY={2}
            paddingX={9}
            minHeight="64px"
            bg="white"
            color="black"
            fontWeight="600"
            position={position}
            zIndex={100}
            shadow={shadow}
          >
            <Flex>
              <Box m="auto">
                <Link to="/">
                  <Image src={FullLogo} />
                </Link>
              </Box>
            </Flex>
            {isLargeScreen && !authorized ? (
              <>
                <Flex
                  flexGrow={1}
                  gap={3}
                  paddingX={10}
                  justifyContent="flex-end"
                >
                  <NavBarLink to="/how">How it works</NavBarLink>
                  <NavBarLink to="/buyer">Homebuyers</NavBarLink>
                  <NavBarLink to="/investor">Investors</NavBarLink>
                  <NavBarLink to="/company">Mission</NavBarLink>
                </Flex>
                <FeatureGate feature="show_app" loadingComponent={null}>
                  <Flex gap={2}>
                    {authorized ? (
                      <NavBarButton data-method="delete" href="/users/sign_out">
                        <Text>{t('appBar.logOut')}</Text>
                      </NavBarButton>
                    ) : (
                      <NavBarButton href="/users/sign_in">
                        <Text>{t('appBar.logIn')}</Text>
                      </NavBarButton>
                    )}
                  </Flex>
                </FeatureGate>
              </>
            ) : (
              <Flex>
                <IconButton
                  height="2rem"
                  width="2rem"
                  m="auto"
                  ref={btnRef}
                  onClick={onOpen}
                  variant="primary"
                  aria-label="menu button"
                  icon={<HamburgerIcon height="24px" width="auto" />}
                />
              </Flex>
            )}
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent backgroundColor="primary.800" color="white">
                <DrawerCloseButton variant="primary" />
                <Observer>
                  {() => {
                    const { user } = rootStore;
                    if (!user) return <DrawerHeader>Welcome!</DrawerHeader>;

                    return <DrawerHeader>{user.email}</DrawerHeader>;
                  }}
                </Observer>
                <DrawerBody padding={0} backgroundColor="white">
                  <SimpleGrid columns={1}>
                    {authorized ? (
                      <>
                        <GridItem>
                          <DrawerLink
                            icon={MdTravelExplore}
                            title="Browse"
                            to="/browse"
                            onClick={onClose}
                          />
                        </GridItem>
                        <GridItem>
                          <DrawerLink
                            icon={MdTravelExplore}
                            title="Find buyers"
                            to="/homebuyer/profiles"
                            onClick={onClose}
                          />
                        </GridItem>
                        <GridItem>
                          <DrawerLink
                            icon={MdHome}
                            title="Listings"
                            to="/listings"
                            onClick={onClose}
                          />
                        </GridItem>
                      </>
                    ) : (
                      <>
                        <GridItem>
                          <DrawerLink
                            title="How it works"
                            to="/how"
                            onClick={onClose}
                          />
                        </GridItem>
                        <GridItem>
                          <DrawerLink
                            title="Homebuyers"
                            to="/buyer"
                            onClick={onClose}
                          />
                        </GridItem>
                        <GridItem>
                          <DrawerLink
                            title="Investors"
                            to="/investor"
                            onClick={onClose}
                          />
                        </GridItem>
                        <GridItem>
                          <DrawerLink
                            title="Mission"
                            to="/company"
                            onClick={onClose}
                          />
                        </GridItem>
                      </>
                    )}
                  </SimpleGrid>
                </DrawerBody>
                <FeatureGate feature="show_app" loadingComponent={null}>
                  <DrawerFooter>
                    {authorized ? (
                      <NavBarButton data-method="delete" href="/users/sign_out">
                        <Text>{t('appBar.logOut')}</Text>
                      </NavBarButton>
                    ) : (
                      <NavBarButton href="/users/sign_in">
                        <Text>{t('appBar.logIn')}</Text>
                      </NavBarButton>
                    )}
                  </DrawerFooter>
                </FeatureGate>
              </DrawerContent>
            </Drawer>
          </Flex>
        );
      }}
    </Observer>
  );
};
