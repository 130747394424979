import React, { useRef } from 'react';
import { useListingsStore } from 'components/listings/stores/listings_store';
import {
  Box,
  Center,
  Checkbox,
  GridItem,
  Input,
  MenuButton,
  MenuGroup,
  MenuList,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Observer } from 'mobx-react';
import CustomMenu from 'components/listings/toolbar/custom_menu';
import CustomMenuItem from 'components/listings/toolbar/custom_menu_item';
import PrimaryButton from 'components/shared/primary_button';

const Toolbar = () => {
  const store = useListingsStore();
  const changedRef = useRef(false);
  const onMenuClose = () => {
    if (!changedRef.current) return;
    changedRef.current = false;
    store.refresh();
  };

  return (
    <Box p={2}>
      <Center>
        <CustomMenu onClose={onMenuClose}>
          <MenuButton as={PrimaryButton}>Price</MenuButton>
          <MenuList>
            <CustomMenuItem>
              <Observer>
                {() => (
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    onChange={({ target: { value } }) => {
                      changedRef.current = true;
                      const val = value.replace(/\D+/g, '');
                      store.setMinPrice(val ? parseInt(val, 10) : null);
                    }}
                    value={`$${store.minPrice?.toLocaleString('en-US') ?? ''}`}
                  />
                )}
              </Observer>
              <Text p={2}>-</Text>
              <Observer>
                {() => (
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    onChange={({ target: { value } }) => {
                      changedRef.current = true;
                      const val = value.replace(/\D+/g, '');
                      store.setMaxPrice(val ? parseInt(val, 10) : null);
                    }}
                    value={`$${store.maxPrice?.toLocaleString('en-US') ?? ''}`}
                  />
                )}
              </Observer>
            </CustomMenuItem>
          </MenuList>
        </CustomMenu>
        <Observer>
          {() => {
            const { propertyTypes = [] } = store.listingsOptions ?? {};
            if (propertyTypes.length === 0) return null;

            return (
              <CustomMenu onClose={onMenuClose}>
                {() => (
                  <>
                    <MenuButton as={PrimaryButton} ml={2}>
                      Property Type
                    </MenuButton>
                    <MenuList>
                      <CustomMenuItem>
                        <SimpleGrid
                          columns={1}
                          p={3}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {propertyTypes.map((propType) => (
                            <GridItem key={propType}>
                              <Observer>
                                {() => (
                                  <Checkbox
                                    onChange={() => {
                                      changedRef.current = true;
                                      store.togglePropertyType(propType);
                                    }}
                                    isChecked={store.selectedPropertyTypes.includes(
                                      propType,
                                    )}
                                  >
                                    <Text>{propType.replace('_', ' ')}</Text>
                                  </Checkbox>
                                )}
                              </Observer>
                            </GridItem>
                          ))}
                        </SimpleGrid>
                      </CustomMenuItem>
                    </MenuList>
                  </>
                )}
              </CustomMenu>
            );
          }}
        </Observer>
        <CustomMenu onClose={onMenuClose}>
          {() => (
            <>
              <MenuButton ml={2} as={PrimaryButton}>
                Beds & Baths
              </MenuButton>
              <MenuList>
                <MenuGroup title="Beds">
                  <CustomMenuItem>
                    <Observer>
                      {() => (
                        <Input
                          onClick={(e) => e.stopPropagation()}
                          type="number"
                          onChange={({ target: { value } }) => {
                            changedRef.current = true;
                            store.setBeds(value ? parseInt(value, 10) : null);
                          }}
                          value={store.beds ?? ''}
                        />
                      )}
                    </Observer>
                  </CustomMenuItem>
                </MenuGroup>
                <MenuGroup title="Baths">
                  <CustomMenuItem>
                    <Observer>
                      {() => (
                        <Input
                          onClick={(e) => e.stopPropagation()}
                          type="number"
                          onChange={({ target: { value } }) => {
                            changedRef.current = true;
                            store.setBaths(value ? parseInt(value, 10) : null);
                          }}
                          value={store.baths ?? ''}
                        />
                      )}
                    </Observer>
                  </CustomMenuItem>
                </MenuGroup>
              </MenuList>
            </>
          )}
        </CustomMenu>
      </Center>
    </Box>
  );
};

export default Toolbar;
