import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Center,
} from '@chakra-ui/react';
import {
  Identifiable,
  ItemToMarkerProps,
  ListItemContent,
} from 'components/shared/search_map/types';
import {
  MapStoreContext,
  MapStore,
} from 'components/shared/search_map/stores/map_store';
import Map from 'components/shared/search_map/map';
import ItemList from 'components/shared/search_map/item_list';
import SearchBar from 'components/shared/search_map/search_bar';

export type SearchMapProps<T extends Identifiable> = {
  mapOptions?: google.maps.MapOptions;
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
  mapStore: MapStore<T>;
  itemToMarkerProps: ItemToMarkerProps<T>;
  ListItemComponent: ListItemContent<T>;
  OnClickComponent?: ListItemContent<T>;
  Toolbar?: React.ComponentType;
  SortButton?: React.ComponentType;
};

const SearchMap = observer(
  <T extends Identifiable>({
    mapOptions,
    defaultCenter,
    defaultZoom,
    mapStore,
    itemToMarkerProps,
    ListItemComponent,
    OnClickComponent,
    Toolbar,
    SortButton,
  }: SearchMapProps<T>) => {
    const { items } = mapStore;
    const [item, setItem] = useState<T>(null);

    return (
      <MapStoreContext.Provider value={mapStore}>
        <Flex
          direction="column"
          width="100%"
          position="absolute"
          top={0}
          bottom={0}
        >
          <Box mt={3} mx="auto" w="80%" maxW={600} pb={3} flexGrow={0}>
            <Center>
              <SearchBar />
            </Center>
          </Box>
          {Toolbar && (
            <Box my={1} mx="auto" w="80%" pb={3} flexGrow={0}>
              <Toolbar />
            </Box>
          )}
          <Box flexGrow={1} boxShadow="rgb(0 0 0 / 50%) 0px 2px 4px 0px">
            <Flex direction="row" height="100%">
              <Box
                position="relative"
                flexGrow={0}
                overflowY="scroll"
                w="30%"
                h="100%"
              >
                <ItemList
                  ListItemComponent={ListItemComponent}
                  SortButton={SortButton}
                />
              </Box>
              <Box flexGrow={1} height="100%" position="relative">
                <Map
                  items={items}
                  mapper={itemToMarkerProps}
                  mapOptions={mapOptions}
                  defaultCenter={defaultCenter}
                  defaultZoom={defaultZoom}
                  setItem={(_item) => {
                    if (!OnClickComponent) return;

                    setItem(_item);
                  }}
                />
              </Box>
            </Flex>
          </Box>
          {OnClickComponent && (
            <Modal isOpen={Boolean(item)} onClose={() => setItem(null)}>
              <ModalOverlay />
              <ModalContent>
                <OnClickComponent {...item} />
              </ModalContent>
            </Modal>
          )}
        </Flex>
      </MapStoreContext.Provider>
    );
  },
);

export default SearchMap;
