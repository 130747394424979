import React, { useEffect, useMemo, useState } from 'react';
import { observer, Observer } from 'mobx-react';
import MobxReactForm from 'mobx-react-form';
import { createForm, FormContext, handleSubmitError } from 'forms/base_form';
import Field from 'components/shared/fields/field';
import ImageField, { getFileData } from 'components/shared/fields/image_field';
import TextField from 'components/shared/fields/text_field';
import { deepCamelToSnakeCase } from 'utils/string_conversion';
import useRootStore from 'hooks/use_root_store';
import { useNavigate } from 'react-router-dom';
import AddressField from 'components/shared/fields/address_field';
import {
  Button,
  Box,
  Heading,
  Container,
  Stack,
  HStack,
  Text,
} from '@chakra-ui/react';
import Loading from 'components/shared/loading';
import MoneyField from 'components/shared/fields/money_field';
import fields from './fields';

export default observer(({ edit = false }: { edit?: boolean }) => {
  const rootStore = useRootStore();
  const {
    api,
    user: { profile },
  } = rootStore;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const form = useMemo<MobxReactForm>(
    () => createForm({
      fields: fields(profile),
      hooks: {
        onSuccess: async (_form) => {
          setLoading(true);
          const values = _form.values();
          const { files } = _form.$('buyerProfile.profilePic');
          if (files?.length === 1) {
            try {
              values.buyerProfile.profilePic = await getFileData(files[0]);
            } catch (e) {
              console.log(e);
              _form.invalidate('error getting profile picture data');
              setLoading(false);
              return;
            }
          } else {
            values.buyerProfile.profilePic = null;
          }
          const { place: clPlace } = _form.$('buyerProfile.currentLocation');
          values.buyerProfile.currentLocation = clPlace;
          const { place: dlPlace } = _form.$('buyerProfile.desiredLocation');
          values.buyerProfile.desiredLocation = dlPlace;

          const payload = deepCamelToSnakeCase(values);
          try {
            await api.postProfile(payload);
            try {
              await rootStore.fetchUser();
            } catch (e) {
              console.log(e);
            }
            navigate('/homebuyer/profile');
          } catch (e) {
            handleSubmitError(_form, e);
          }
          setLoading(false);
        },
      },
    }),
    [profile],
  );

  useEffect(() => {
    if (edit || !profile?.buyerProfile) return;

    navigate('/homebuyer/profile');
  }, [profile]);

  if (loading) return <Loading />;

  return (
    <FormContext.Provider value={form}>
      <Container maxW="65%" pt={10}>
        <form onSubmit={form.onSubmit}>
          <Heading size="2xl">It&apos;s Time to share some details</Heading>
          <HStack>
            <Heading size="l">
              Please fill out this form to create a fundraising campaign
            </Heading>
            <ImageField
              field={form.$('buyerProfile.profilePic')}
              accept="image/jpeg, image/png"
            />
          </HStack>
          <Stack>
            <Box>
              <Field field={form.$('firstName')} />
            </Box>
            <Box>
              <Field field={form.$('middleName')} />
            </Box>
            <Box>
              <Field field={form.$('lastName')} />
            </Box>

            <Box>
              <TextField field={form.$('buyerProfile.bio')} />
            </Box>
            <Box>
              <Box>
                <AddressField field={form.$('buyerProfile.currentLocation')} />
              </Box>
              <Box>
                <AddressField field={form.$('buyerProfile.desiredLocation')} />
              </Box>
              <Box>
                <Field field={form.$('buyerProfile.occupation')} />
              </Box>
              <Box>
                <Field field={form.$('buyerProfile.employer')} />
              </Box>
              <Box>
                <Field field={form.$('phoneNumber')} mask="+1 (999) 999-9999" />
              </Box>
              <Box>
                <Field field={form.$('ssn')} mask="999-99-9999" />
              </Box>
              <Box>
                <MoneyField field={form.$('buyerProfile.fundraiseGoal')} />
              </Box>
            </Box>

            <HStack>
              <Box>
                <Button type="submit" onClick={form.onSubmit}>
                  Submit
                </Button>
              </Box>
              <Box>
                <Button type="button" onClick={form.onReset} color="error">
                  Reset
                </Button>
              </Box>
            </HStack>

            <Observer>
              {() => form.error && (
              <Box>
                <Text>{form.error}</Text>
              </Box>
              )}
            </Observer>
          </Stack>
        </form>
      </Container>
    </FormContext.Provider>
  );
});
