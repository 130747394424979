import React from 'react';
import {
  Pagination,
  PaginationContainer,
  PaginationPage,
  PaginationPageGroup,
} from '@ajna/pagination';
import {
  MdArrowBack,
  MdArrowForward,
  MdFirstPage,
  MdLastPage,
} from 'react-icons/md';
import { IconButton } from '@chakra-ui/react';

const ListPagination = ({
  pagesCount,
  currentPage,
  setCurrentPage,
  pages,
  pageButtonsStart,
  pageButtonsEnd,
}: {
  pagesCount: number;
  currentPage: number;
  setCurrentPage: (number) => void;
  pages: number[];
  pageButtonsStart: number;
  pageButtonsEnd: number;
}) => {
  const iconSize = 25;

  return (
    <Pagination
      pagesCount={pagesCount}
      onPageChange={setCurrentPage}
      currentPage={currentPage}
    >
      <PaginationContainer
        align="center"
        justify="space-between"
        w="full"
        p={4}
      >
        <PaginationPageGroup>
          <IconButton
            aria-label="first page"
            colorScheme="primary"
            color="white"
            scale={2}
            icon={<MdFirstPage size={iconSize} />}
            disabled={currentPage === pages[0]}
            onClick={() => setCurrentPage(1)}
          />
          <IconButton
            aria-label="previous page"
            variant="primary"
            icon={<MdArrowBack size={iconSize} />}
            disabled={currentPage === pages[0]}
            onClick={() => setCurrentPage(currentPage - 1)}
          />
        </PaginationPageGroup>
        <PaginationPageGroup>
          {pages.slice(pageButtonsStart, pageButtonsEnd).map((page) => (
            <PaginationPage
              colorScheme="primary"
              color="white"
              px={2}
              key={`pagination_page_${page}`}
              page={page}
              _current={{ variant: 'secondary' }}
            />
          ))}
        </PaginationPageGroup>
        <PaginationPageGroup>
          <IconButton
            aria-label="previous page"
            variant="primary"
            icon={<MdArrowForward size={iconSize} />}
            disabled={currentPage === pages[pages.length - 1]}
            onClick={() => setCurrentPage(currentPage + 1)}
          />
          <IconButton
            aria-label="first page"
            variant="primary"
            icon={<MdLastPage size={iconSize} />}
            disabled={currentPage === pages[pages.length - 1]}
            onClick={() => setCurrentPage(pages[pages.length - 1])}
          />
        </PaginationPageGroup>
      </PaginationContainer>
    </Pagination>
  );
};

export default ListPagination;
