import React, { ReactNode } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LeadFormV2 from 'components/home/forms/lead_form_v2';

const QuestionText = ({ children }: { children: ReactNode }) => (
  <Text
    letterSpacing={0}
    lineHeight="32px"
    textAlign="center"
    w="fit-content"
    fontSize="24px"
    fontStyle="normal"
    fontWeight={800}
  >
    {children}
  </Text>
);

const BottomHero = () => {
  const { t } = useTranslation();
  return (
    <Flex alignItems="flex-start" direction="column" w="90%" ml="5%" mr="auto">
      <Flex
        alignItems="center"
        alignSelf="stretch"
        bgColor="primary.50"
        borderRadius="40px"
        direction="column"
        gap="10px"
        justifyContent="center"
        p="80px 0px"
      >
        <Flex
          alignItems="center"
          direction="column"
          gap="32px"
          textAlign="center"
          w="100%"
          px={10}
        >
          <QuestionText>{t('pages.landing.bottomHeroQuestion1')}</QuestionText>
          <QuestionText>{t('pages.landing.bottomHeroQuestion2')}</QuestionText>
          <Text
            fontWeight={400}
            letterSpacing={0}
            lineHeight="28px"
            textAlign="center"
            w="fit-content"
          >
            {t('pages.landing.followUs')}
          </Text>
          <LeadFormV2 />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BottomHero;
