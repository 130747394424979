import React, { useEffect, useState } from 'react';
import useRootStore from 'hooks/use_root_store';
import {
  Box,
  Stack,
  Icon,
  Button,
  Center,
  Container,
  Flex,
  GridItem,
  Text,
} from '@chakra-ui/react';
import Loading from 'components/shared/loading';
import { useNavigate } from 'react-router-dom';
import { Observer } from 'mobx-react';
import SsnViewer from 'components/shared/ssn_viewer';

export default () => {
  const rootStore = useRootStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;

    rootStore.fetchUser().finally(() => setLoading(false));
  }, [loading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Observer>
      {() => {
        const navigate = useNavigate();
        const {
          user: {
            profile,
            bankAccount: { name: nameOnAccount, accountNumber, routingNumber },
          },
        } = rootStore;

        useEffect(() => {
          if (profile?.investorProfile) return;

          navigate('/');
        }, [profile]);

        if (!profile?.investorProfile) {
          return <Loading />;
        }

        const {
          firstName,
          middleName,
          lastName,
          phoneNumber,
          ssn,
          investorProfile: {
            address: { formattedAddress },
          },
        } = profile;

        return (
          <Container maxW={['100%', '40%']}>
            <Center>
              <Box border="1px" borderColor="gray.200" pl="35px" w="full">
                <GridItem>
                  <Flex p="4" justifyContent="flex-end" direction="row">
                    <Button
                      variant="neutral"
                      onClick={() => navigate('/investor/profile/edit')}
                    >
                      <Text>Edit</Text>
                    </Button>
                  </Flex>
                </GridItem>
                <Box mb="10"> </Box>
                <Stack spacing={4}>
                  <Box bg="primary.50" p="1" w="60%">
                    <Icon pl="1" pr="2" />
                    Personal information
                  </Box>
                  <Text pt="4">Name</Text>
                  <Text>
                    {[firstName, middleName, lastName]
                      .filter((s) => s)
                      .join(' ')}
                  </Text>
                  <Text>Phone number</Text>
                  <Text>
                    {' '}
                    {phoneNumber}
                  </Text>
                  <Text> Social Security Number</Text>
                  <SsnViewer ssn={ssn} />
                  <Text>Address</Text>
                  <Text>{formattedAddress}</Text>
                  <Box p="5" />
                  <Box bg="primary.50" p="1" w="60%">
                    <Icon pl="1" pr="2" />
                    Bank Account information
                  </Box>
                  <Text pt="45">Name on account</Text>
                  <Text>{nameOnAccount}</Text>
                  <Text>Account number</Text>
                  <Text>{accountNumber}</Text>
                  <Text>routing number:</Text>
                  <Text>{routingNumber}</Text>
                  <Box p="5" />
                </Stack>
              </Box>
            </Center>
          </Container>
        );
      }}
    </Observer>
  );
};
