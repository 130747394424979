import React from 'react';
import { Box, Image } from '@chakra-ui/react';

export type Orientation = 'topLeft' | 'topRight';

const OverlapImage = ({
  src,
  alt,
  orientation,
}: {
  src: string;
  alt: string;
  orientation: Orientation;
}) => {
  const borderRadius = orientation === 'topLeft' ? '80px 0px 80px 0px' : '0px 80px 0px 80px';
  return (
    <Box h="100%" pos="relative" w="100%">
      <Box
        bgColor="primary.50"
        borderRadius={borderRadius}
        height="95%"
        left={orientation === 'topLeft' ? 0 : '10%'}
        pos="absolute"
        top="5%"
        w="90%"
      />
      <Image
        alt={alt}
        src={src}
        h="95%"
        left={orientation === 'topLeft' ? '10%' : 0}
        pos="absolute"
        top={0}
        w="90%"
        objectFit="cover"
        overflow="hidden"
        borderRadius={borderRadius}
      />
    </Box>
  );
};

export default OverlapImage;
