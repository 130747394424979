import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useRootStore from 'hooks/use_root_store';
import { observer, Observer } from 'mobx-react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Flex, Box, FlexProps } from '@chakra-ui/react';
import Home from 'components/home';
import HomeBuyer from 'components/home_buyer';
import Investor from 'components/investor';
import Browse from 'components/browse';
import Listings from 'components/listings';
import AppBar from 'components/shared/app_bar';
import Loading from 'components/shared/loading';
import Footer from 'components/shared/footer';
import Goals from 'components/goals';

const mapLibraries = ['places'];

const AuthedContent = observer(() => {
  const { googleApiKey } = useRootStore();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleApiKey,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    libraries: mapLibraries,
  });

  if (!isLoaded) return <Loading />;

  return (
    <Routes>
      <Route path="/homebuyer">{HomeBuyer}</Route>
      <Route path="/investor">{Investor}</Route>
      <Route path="/browse" element={<Browse />} />
      <Route path="/listings" element={<Listings />} />
      <Route path="/" element={<Goals />} />
    </Routes>
  );
});

type MainContentVariant = 'normal' | 'fixed-header';

const MainContent = ({
  variant = 'normal',
}: {
  variant?: MainContentVariant;
}) => {
  const rootStore = useRootStore();
  let flexProps: FlexProps = {};
  if (variant === 'fixed-header') {
    flexProps = { pt: '64px' };
  }

  return (
    <div>
      {variant === 'fixed-header' && (
        <AppBar position="fixed" shadow="bottom" />
      )}
      <Flex
        direction="column"
        position="absolute"
        left={0}
        right={0}
        height="100vh"
        {...flexProps}
      >
        {variant === 'normal' && (
          <Box flexGrow={0}>
            <AppBar />
          </Box>
        )}
        <Box flexGrow={1} position="relative">
          <Observer>
            {() => {
              if (!rootStore.authStore.apiKey) {
                return <Home />;
              }

              return <AuthedContent />;
            }}
          </Observer>
        </Box>
        <Footer />
      </Flex>
    </div>
  );
};

export default MainContent;
