import React from 'react';
import { Profile } from 'utils/api';
import {
  Avatar,
  Center,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

const ListItemContent = ({
  firstName,
  lastName,
  buyerProfile: {
    profilePicUrl,
    occupation,
    employer,
    desiredLocation,
    currentLocation,
  },
}: Profile) => (
  <SimpleGrid w="100%" columns={1} p={3} spacing={3}>
    <GridItem
      boxShadow="rgb(0 0 0 / 30%) 0px 2px 4px 0px"
      p={3}
      borderRadius={5}
    >
      <SimpleGrid columns={1} spacing={1}>
        <GridItem>
          <Center>
            <Avatar
              size="2xl"
              name={[firstName, lastName].filter((s) => s).join(' ')}
              src={profilePicUrl}
            />
          </Center>
        </GridItem>
        <GridItem>
          <SimpleGrid columns={1} spacing={1}>
            <GridItem>
              <Center>
                <Heading>
                  {[firstName, lastName].filter((s) => s).join(' ')}
                </Heading>
              </Center>
            </GridItem>
            <GridItem>
              <Text color="grey" fontSize="sm">
                {`${occupation} at ${employer} living in ${currentLocation.city}, ${currentLocation.state}`}
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">{`Wants to buy a home in ${desiredLocation.city}, ${desiredLocation.state}`}</Text>
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </SimpleGrid>
    </GridItem>
  </SimpleGrid>
);

export default ListItemContent;
