import React from 'react';
import {
  AbsoluteCenter,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import imagePaths from 'utils/image_paths';
import { useTranslation } from 'react-i18next';
import LeadFormV2 from 'components/home/forms/lead_form_v2';

const TopHero = () => {
  const { t } = useTranslation();
  return (
    <Box
      mt={-500}
      bg="radial-gradient(50% 50% at 50% 50%, rgba(88, 46, 244, 0.13) 0%, rgba(141, 85, 238, 0) 100%)"
      h={1000}
      w="100%"
    >
      <Box h={800} pos="relative" w="100%" mt={300}>
        <Flex
          alignItems="flex-start"
          borderRadius="8px"
          h="503px"
          left={0}
          overflow="hidden"
          pos="absolute"
          top={280}
          w="80%"
          ml="10%"
          mr="auto"
        >
          <Image
            alt="a family moving"
            src={imagePaths['family.png']}
            h="100%"
            w="100%"
            objectFit="cover"
          />
        </Flex>
        <Flex
          alignItems="center"
          display="flex"
          direction="column"
          gap="48px"
          pos="absolute"
          top={0}
          w="100%"
        >
          <Box w="70%" ml="15%" mr="auto" pos="relative">
            <Heading
              as="h1"
              color="#090b2e"
              fontWeight="900"
              fontSize="64px"
              letterSpacing={0}
              lineHeight="77px"
              textAlign="center"
              w="100%"
            >
              {t('pages.landing.heading')}
            </Heading>
            <Text
              color="primary.600"
              fontSize="20px"
              fontWeight={800}
              letterSpacing={0}
              lineHeight="normal"
              textAlign="center"
              w="100%"
            >
              {t('pages.landing.subheading')}
            </Text>
          </Box>
          <Box
            bgColor="primary.50"
            border="1px solid"
            borderColor="neutral.200"
            borderRadius="4px"
            boxShadow="0px 25px 50px -12px #00000040"
            h="85px"
            minW="582px"
            pos="relative"
          >
            <AbsoluteCenter>
              <LeadFormV2 />
            </AbsoluteCenter>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default TopHero;
