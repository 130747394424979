import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import PrimaryButtonLink from 'components/shared/primary_button_link';
import { useTranslation } from 'react-i18next';
import { Family1Image } from 'components/home/landing/shared/images';

const Section1 = () => {
  const { t } = useTranslation();
  return (
    <Box h="709px" w="100%" mt={100}>
      <Box h="709px" pos="relative" w="100%">
        <Box
          backgroundColor="primary.300"
          borderRadius="50%"
          filter="blur(300px)"
          h="406px"
          left={10}
          opacity={0.5}
          pos="absolute"
          top="151px"
          w="406px"
        />
        <Flex alignItems="center" gap="120px" w="100%">
          <Flex
            alignItems="flex-start"
            direction="column"
            gap="32px"
            pos="relative"
            ml="10%"
            mr="auto"
            w="30%"
          >
            <Text
              color="black"
              fontSize="24px"
              fontStyle="normal"
              fontWeight={800}
              alignSelf="stretch"
              letterSpacing={0}
              lineHeight="32px"
            >
              {t('pages.landing.section1Title')}
            </Text>
            <Text
              color="black"
              fontSize="16px"
              fontStyle="normal"
              fontWeight={400}
              alignSelf="stretch"
              letterSpacing={0}
              lineHeight="24px"
            >
              {t('pages.landing.section1Body')}
            </Text>
            <PrimaryButtonLink to="/how">
              {t('pages.landing.section1Button')}
            </PrimaryButtonLink>
          </Flex>
          <Box h={709} w="40%" mr="10%">
            <Family1Image />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Section1;
