import React from 'react';
import {
  Box, Container, Image, Text, useMediaQuery,
} from '@chakra-ui/react';
import imagePaths from 'utils/image_paths';

const HeaderContent = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => (
  <>
    <Text fontSize={['30px', '48px']} fontWeight="800">
      {title}
    </Text>
    <Box h={4} />
    <Text fontSize={['16px', '20px']} fontWeight="800">
      {subtitle}
    </Text>
  </>
);

interface HeaderProps {
  children: JSX.Element | JSX.Element[];
  image: string;
}

const LargeHeader = ({ children, image }: HeaderProps) => (
  <>
    <Image ml={['44%']} height={['656px']} width={['783px']} src={image} />
    <Box
      boxShadow="lg"
      p="6"
      bg="white"
      opacity=".8"
      borderRadius="12px"
      position={['relative', null, 'absolute']}
      height="343px"
      width={['320px', '640px']}
      top={['10px', '102px']}
      left={['10%', '15%']}
    >
      {children}
    </Box>
  </>
);

const SmallHeader = ({ children, image }: HeaderProps) => (
  <Container width="100%">
    {children}
    <Image pt="10px" src={image} />
  </Container>
);

const defaultTitle = 'Homeslice is real estate for the people';
const defaultSubtitle = 'We crowdfund down payments for first time home buyers in exchange for a slice of home equity.';

const SubHeader = ({
  title = defaultTitle,
  subtitle = defaultSubtitle,
  image = imagePaths['house.png'],
}: {
  title: string;
  subtitle: string;
  image?: string;
}) => {
  const [largeScreen] = useMediaQuery('(min-width: 768px)');
  const content = <HeaderContent title={title} subtitle={subtitle} />;

  return largeScreen ? (
    <LargeHeader image={image}>{content}</LargeHeader>
  ) : (
    <SmallHeader image={image}>{content}</SmallHeader>
  );
};

export default SubHeader;
