import { createContext, useContext } from 'react';
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import vjf from 'mobx-react-form/lib/validators/VJF';
import validatorjs from 'validatorjs';
import { snakeToCamelCase } from 'utils/string_conversion';

const plugins = {
  dvr: dvr(validatorjs),
  vjf: vjf(),
};

export interface FormHooks {
  onSuccess?: (_: MobxReactForm) => void;
  onError?: (_: MobxReactForm) => void;
  onClear?: (_: MobxReactForm) => void;
  onAdd?: (_: MobxReactForm) => void;
  onDel?: (_: MobxReactForm) => void;
}

export const createForm = ({
  fields = {},
  hooks = {},
  options = {},
}: {
  hooks?: FormHooks;
  fields?: object;
  options?: object;
}): MobxReactForm => new MobxReactForm({ fields }, { plugins, hooks, options });

export const FormContext = createContext<MobxReactForm>(null);
export const useForm = (): MobxReactForm => useContext(FormContext);

export const handleSubmitError = (form: MobxReactForm, error) => {
  const { response } = error;
  if (response?.data) {
    const {
      data: { errors = {} },
    } = response;
    interface Errors {
      [key: string]: Array<string>;
    }
    const errorEntries = Object.entries(errors as Errors);
    if (errorEntries.length > 0) {
      errorEntries.forEach(([k, v]) => {
        const field = form.$(`${snakeToCamelCase(k)}`);
        field.invalidate(`${field.label} ${v.join(', ')}`);
      });
      return;
    }
  }
  console.log(error);
  form.invalidate('An error occurred');
};
