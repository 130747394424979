import React from 'react';
import imagePaths from 'utils/image_paths';
import InfoPageLayout from 'components/home/shared/info_page_layout';

const How = () => (
  <InfoPageLayout
    i18nPrefix="pages.home.how"
    headerImage={imagePaths['house_light_blue.png']}
    sections={{
      crowdfunds: ['communityInvestors'],
      investorsReceive: ['exchangeEquity'],
      buyersMortgage: ['buyersLiveInHome'],
      buyersPackBack: ['timeFrame'],
    }}
  />
);

export default How;
