export const camelToSnakeCase = (str: string): string => str.replace(/([A-Z])/g, '_$1').toLowerCase();

const snakeToCamelGroupReplaceFunc = (group: string): string => group.toUpperCase().replace('-', '').replace('_', '');
export const snakeToCamelCase = (str: string): string => str.toLowerCase().replace(/([-_][a-z])/g, snakeToCamelGroupReplaceFunc);

const createDeepConversion = (
  f: (string) => string,
): (<T extends object>(T) => T) => {
  const conversion = <T extends object>(obj: T) => {
    const converted = {};
    Object.entries(obj).forEach((arr) => {
      const [key, value] = arr;
      if (Array.isArray(value)) {
        converted[f(key)] = value;
      } else if (value && typeof value === 'object') {
        converted[f(key)] = conversion(value);
      } else {
        converted[f(key)] = value;
      }
    });
    return converted as T;
  };

  return conversion;
};

export const deepCamelToSnakeCase = createDeepConversion(camelToSnakeCase);
export const deepSnakeToCamelCase = createDeepConversion(snakeToCamelCase);
