import React, { useEffect, useMemo, useState } from 'react';
import useRootStore from 'hooks/use_root_store';
import {
  SimpleGrid,
  Center,
  Box,
  Image,
  Stack,
  Heading,
  StackDivider,
  VStack,
  Button,
  Text,
  Flex,
  Container,
  List,
  ListItem,
  useColorModeValue,
  Progress,
} from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';
import { Observer } from 'mobx-react';

import Loading from 'components/shared/loading';
import ProfilesStore from 'components/home_buyer/stores/profiles_store';
import imagePaths from 'utils/image_paths';

export default () => {
  const rootStore = useRootStore();
  const profilesStore = useMemo(() => new ProfilesStore(rootStore.api), []);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const getProfile = () => {
    if (id) {
      return profilesStore.profile;
    }

    const {
      user: { profile },
    } = rootStore;
    return profile;
  };

  useEffect(() => {
    if (!loading) return;

    if (id) {
      profilesStore
        .fetchProfile(parseInt(id, 10))
        .finally(() => setLoading(false));
    } else {
      rootStore.fetchUser().finally(() => setLoading(false));
    }
  }, [loading, id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Center>
      <Box w={['100', '80%']}>
        <Observer>
          {() => {
            const navigate = useNavigate();
            const profile = getProfile();

            useEffect(() => {
              if (profile?.buyerProfile) return;

              navigate('/');
            }, [profile]);

            if (!profile?.buyerProfile) {
              return <Loading />;
            }

            return (
              <Container pt={12} maxW="7xl">
                <Flex>
                  <Image
                    rounded="md"
                    alt="family image"
                    src={imagePaths['family2.jpg']}
                    fit="cover"
                    align="center"
                    w="100%"
                    h={{ base: '100%', sm: '400px', lg: '500px' }}
                  />
                </Flex>

                <Box pt="10">
                  <Progress hasStripe colorScheme="purple" value={80} />
                  <Box>
                    <Text fontWeight="bold" size="xl">
                      80 out of 100 shares funded
                    </Text>
                  </Box>
                </Box>

                <SimpleGrid
                  columns={{ base: 1, lg: 2 }}
                  spacing={{ base: 8, md: 10 }}
                  py={{ base: 18, md: 18 }}
                >
                  <Stack spacing={{ base: 6, md: 10 }}>
                    <Box as="header">
                      <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                      >
                        {[
                          profile.firstName,
                          profile.middleName,
                          profile.lastName,
                        ]
                          .filter((s) => s)
                          .join(' ')}
                        {' '}
                      </Heading>

                      <Text
                        color={useColorModeValue('gray.900', 'gray.400')}
                        fontWeight={300}
                        fontSize="2xl"
                      >
                        Seeking
                        {' '}
                        {profile.buyerProfile.fundraiseGoal}
                        {' '}
                        of
                        {' '}
                        {profile.buyerProfile.fundraiseGoal * 5}
                      </Text>
                      <Text fontSize="xl">
                        {' '}
                        for a home in
                        {' '}
                        {profile.buyerProfile.desiredLocation.city}
                        ,
                        {' '}
                        {profile.buyerProfile.desiredLocation.state}
                        {' '}
                      </Text>
                    </Box>

                    <Stack
                      spacing={{ base: 4, sm: 6 }}
                      direction="column"
                      divider={(
                        <StackDivider
                          borderColor={useColorModeValue(
                            'gray.200',
                            'gray.600',
                          )}
                        />
                      )}
                    >
                      <VStack spacing={{ base: 4, sm: 6 }}>
                        <Text
                          color={useColorModeValue('gray.500', 'gray.400')}
                          fontSize="2xl"
                          fontWeight="300"
                        />
                        <Text fontSize="lg">
                          {profile.buyerProfile.bio}
                          {' '}
                          Lorem ipsum for space. The
                          Smiths have been local to Asheville for their whole
                          lives. Their rent has been raised 3 out of the last 5
                          years and with the kids going to school, it&apos;s
                          time to settle in to their long term home
                        </Text>
                      </VStack>
                      <Box>
                        <Text
                          fontSize={{ base: '16px', lg: '18px' }}
                          color={useColorModeValue('purple.500', 'purple.300')}
                          fontWeight="500"
                          mb="4"
                        >
                          family details
                        </Text>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                          <List spacing={2}>
                            <ListItem>locally raised</ListItem>
                            <ListItem>member of PTA</ListItem>
                            {' '}
                            <ListItem>2 children</ListItem>
                          </List>
                          <List spacing={2}>
                            <ListItem>teacher</ListItem>
                            <ListItem>environment forward thinking</ListItem>
                            <ListItem>dog lovers</ListItem>
                          </List>
                        </SimpleGrid>
                      </Box>
                      <Box>
                        <Text
                          fontSize={{ base: '16px', lg: '18px' }}
                          color={useColorModeValue('purple.500', 'purple.300')}
                          fontWeight="500"
                          mb="4"
                        >
                          what they&apos;re looking for
                        </Text>

                        <List spacing={2}>
                          <ListItem>.5+ acres</ListItem>
                          <ListItem>3+ bedrooms</ListItem>
                          <ListItem>2+ bathrooms</ListItem>
                          <ListItem>
                            ideally can grow food in their home garden
                          </ListItem>
                        </List>
                      </Box>
                    </Stack>

                    <Button
                      bg={useColorModeValue('purple.600', 'purple.50')}
                      color={useColorModeValue('white', 'purple.600')}
                      w="full"
                      mt={8}
                      size="lg"
                      py="7"
                      _hover={{
                        transform: 'translateY(2px)',
                        boxShadow: 'lg',
                      }}
                    >
                      Invest in the Smiths!
                    </Button>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {/* <MdLocalShipping /> */}
                    </Stack>
                  </Stack>
                  <Stack>
                    <Image src="https://www.google.com/maps/vt/data=O-sKxOq_28VoCdCNBrHDAbVGyJqNQZI7FMvuJwIJID6Y55Fzy5h7hoKOaH7FA0KXmbD4sbkBzvNYhrhD_zBL0ih71qwqhBfykCPmIvpajKJyYmEakkOdyBPF7ZhGbaoG2-To67CJ0P61MWq_zoi17VDoFzVybIvqGNTfb4Ll7vOfQrYS3SWB0cHdbiK5WMhm2wBLpcVEfOMFspsq_gujk7T6IQaSIDW9H6-GjYzVTnKub8k" />
                    {/* <Box>asheville map ?with available properties as hoverable pins?</Box> */}

                    <Text as="span" fontWeight="bold">
                      Asheville, NC Housing Market
                    </Text>
                    <Text as="span">
                      In June 2022, the median listing home price in Asheville,
                      NC was $499K, trending up 10.9% year-over-year. The median
                      listing home price per square foot was $309. The median
                      home sold price was $535.5K.
                    </Text>
                    <Image src="./asheville.png" />
                  </Stack>
                </SimpleGrid>

                <Button
                  variant="neutral"
                  onClick={() => navigate('/homebuyer/profile/edit')}
                >
                  <Text>Edit</Text>
                </Button>
              </Container>
            );
          }}
        </Observer>
      </Box>
    </Center>
  );
};
