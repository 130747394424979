import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

const NavBarLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const active = to === location.pathname;
  const color = active ? 'primary.600' : 'black';
  return (
    <Link to={to}>
      <Text fontWeight="bold" color={color}>
        {children}
      </Text>
    </Link>
  );
};

export default NavBarLink;
