import React, { useRef } from 'react';
import { useListingsStore } from 'components/listings/stores/listings_store';
import {
  Box,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Observer } from 'mobx-react';

const SortButton = () => {
  const store = useListingsStore();
  const changedRef = useRef(false);
  const onMenuClose = () => {
    if (!changedRef.current) return;
    changedRef.current = false;
  };

  return (
    <Box px={2}>
      <Center>
        <Menu onClose={onMenuClose} colorScheme="primary" autoSelect={false}>
          <MenuButton
            borderWidth={1}
            borderStyle="solid"
            borderColor="primary.500"
            borderRadius={5}
            px={2}
          >
            <Text>Sort</Text>
          </MenuButton>
          <Observer>
            {() => (
              <MenuList>
                {Object.entries(store.sortOptions).map(([value, name]) => (
                  <MenuItem
                    key={value}
                    onClick={() => store.setSelectedSort(value)}
                    isDisabled={store.selectedSort === value}
                  >
                    <Text>{name}</Text>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Observer>
        </Menu>
      </Center>
    </Box>
  );
};

export default SortButton;
