import React from 'react';
import {
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  Box,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();

  return (
    <Box w="100%" textAlign="center">
      <Heading>Investment opportunities</Heading>
      <SimpleGrid columns={2}>
        <GridItem onClick={() => navigate('/investor/buyer/1')}>
          <Container w="100%">
            <Text fontWeight="bold">The smith family</Text>
            <Text>
              Me and my wife Anna are looking to buy a forever home for us and
              our 2 kids Max and Steve.
            </Text>
          </Container>
        </GridItem>
        <GridItem onClick={() => navigate('/investor/buyer/1')}>
          <Container w="100%">
            <Text fontWeight="bold">Mike Johnson</Text>
            <Text>Please fund me!</Text>
          </Container>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
