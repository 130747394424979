import React from 'react';
import {
  Button, Container, Heading, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Heading>Success!</Heading>
      <Button onClick={() => navigate('/investor/portfolio')}>
        <Text>Go to portfolio</Text>
      </Button>
    </Container>
  );
};
