import React from 'react';
import imagePaths from 'utils/image_paths';
import InfoPageLayout from 'components/home/shared/info_page_layout';

const Company = () => (
  <InfoPageLayout
    i18nPrefix="pages.home.company"
    headerImage={imagePaths['house.png']}
    sections={{
      americanDream: ['owningHome', 'greedyCorporations'],
      ourMission: ['communitiesThrive', 'fosterCommunities'],
      noCutForHomeslice: ['mostTakeCuts', 'ourBeliefs'],
    }}
  />
);

export default Company;
