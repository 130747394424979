import React from 'react';
import { Profile } from 'utils/api';
import {
  Avatar,
  Stack,
  Text,
  Badge,
  Box,
  Heading,
  Progress,
} from '@chakra-ui/react';

export default ({
  profile: {
    firstName,
    middleName,
    lastName,
    buyerProfile: {
      profilePicUrl,
      bio,
      currentLocation,
      desiredLocation,
      employer,
      occupation,
      fundraiseGoal,
    },
  },
}: {
  profile: Profile;
}) => (
  <Box maxW="560px" w="full" borderWidth="2px" rounded="md" p={6}>
    <Box pt={8} maxW="420" mb={6}>
      <Stack p={2}>
        <Avatar
          size="2xl"
          name={[firstName, lastName].filter((s) => s).join(' ')}
          src={profilePicUrl}
        />
        <Heading pt={3}>
          {[firstName, middleName, lastName].filter((s) => s).join(' ')}
        </Heading>
        <Badge
          maxW="70%"
          rounded="full"
          px="2"
          fontSize="0.8em"
          colorScheme="purple"
        >
          {desiredLocation.city}
          ,
          {desiredLocation.state}
          {' '}
        </Badge>
        <Text color="grey" fontSize="sm">
          {`${occupation} at ${employer} living in ${currentLocation.city}, ${currentLocation.state}`}
        </Text>

        <Text>{`Needs $${fundraiseGoal.toLocaleString('en-US')}`}</Text>
        <Text>{bio}</Text>

        <Box pt={2}>
          <Progress hasStripe colorScheme="purple" value={80} />
          <Box pt={1}>
            <Text fontWeight="bold" size="xl">
              80 out of 100 shares funded
            </Text>
          </Box>
        </Box>
      </Stack>
    </Box>
  </Box>
);
