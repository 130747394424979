import { createContext, useContext } from 'react';
import { Identifiable } from 'components/shared/search_map/types';
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import LoadingStore from 'stores/loading_store';

interface MapStore<T extends Identifiable> extends MapStoreBase<T> {
  setPlace: (place: google.maps.places.PlaceResult) => void;
  setBounds: (bounds: google.maps.LatLngBounds) => void;
}

class MapStoreBase<T extends Identifiable> extends LoadingStore {
  place?: google.maps.places.PlaceResult;

  bounds?: google.maps.LatLngBounds;

  items: T[] = [];

  hoveredItem?: T;

  constructor() {
    super();
    makeObservable(this, {
      items: observable.shallow,
      setItems: action,
      sortedItems: computed,
      hoveredItem: observable,
      setHoveredItem: action,
    });
  }

  setItems = (items: T[]) => {
    this.items = items;
  };

  get sortedItems() {
    return this.items;
  }

  setHoveredItem = (item: T | null) => {
    this.hoveredItem = item;
  };
}

const MapStoreContext = createContext<MapStore<any>>(null);

const useMapStore = <T extends Identifiable>(): MapStore<T> => useContext(MapStoreContext);

export {
  MapStoreContext, useMapStore, MapStore, MapStoreBase,
};
