import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import {
  Identifiable,
  ListItemContent,
} from 'components/shared/search_map/types';
import { usePagination } from '@ajna/pagination';
import {
  Box, Center, Flex, List, ListItem, Text,
} from '@chakra-ui/react';
import ListPagination from 'components/shared/search_map/list_pagination';
import Loading from 'components/shared/loading';
import {
  MapStore,
  useMapStore,
} from 'components/shared/search_map/stores/map_store';

const ItemList = observer(
  <T extends Identifiable>({
    ListItemComponent,
    SortButton,
  }: {
    ListItemComponent: ListItemContent<T>;
    SortButton?: React.ComponentType;
  }) => {
    const store = useMapStore() as MapStore<T>;
    const items = store.sortedItems;
    const itemsRef = useRef(items);
    const pageSize = 10;
    const {
      pagesCount, currentPage, setCurrentPage, pages,
    } = usePagination({
      total: items.length,
      initialState: { pageSize, currentPage: 1 },
    });
    const topDivRef = useRef<HTMLDivElement | null>(null);
    const wrappedSetCurrentPage = (page: number) => {
      if (topDivRef.current) topDivRef.current.scrollTop = 0;

      setCurrentPage(page);
    };

    if (store.loading) return <Loading absolute />;

    if (items !== itemsRef.current) {
      itemsRef.current = items;
      setCurrentPage(1);
    }

    if (items.length === 0) {
      return (
        <Center>
          <Text textAlign="center" fontWeight="extrabold" mt={5}>
            No results
          </Text>
        </Center>
      );
    }

    const itemsStart = (currentPage - 1) * pageSize;
    const itemsEnd = itemsStart + pageSize;

    const pageButtonsCount = 5;
    let pageButtonsStart = currentPage - Math.floor(pageButtonsCount / 2) - 1;
    if (pageButtonsStart < 0) {
      pageButtonsStart = 0;
    }
    const pageButtonsEnd = pageButtonsStart + pageButtonsCount;

    return (
      <Flex direction="column" position="absolute" top={0} bottom={0} w="100%">
        <Center p={3} mt={2}>
          <Flex direction="row" justifyContent="space-between" w="100%" px={2}>
            <Text fontWeight="extrabold">
              {`${items.length} result${items.length !== 1 ? 's' : ''}`}
            </Text>
            {SortButton && <SortButton />}
          </Flex>
        </Center>
        <Box flexGrow={1} overflowY="scroll" ref={topDivRef}>
          <List width="100%">
            {items.slice(itemsStart, itemsEnd).map((item) => (
              <ListItem
                key={item.id}
                onMouseOver={() => store.setHoveredItem(item)}
                onMouseOut={() => store.setHoveredItem(null)}
              >
                <ListItemComponent {...item} />
              </ListItem>
            ))}
          </List>
        </Box>
        <ListPagination
          currentPage={currentPage}
          setCurrentPage={wrappedSetCurrentPage}
          pages={pages}
          pagesCount={pagesCount}
          pageButtonsStart={pageButtonsStart}
          pageButtonsEnd={pageButtonsEnd}
        />
      </Flex>
    );
  },
);

export default ItemList;
