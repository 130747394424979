import { action, makeObservable, observable } from 'mobx';

class LoadingStore {
  loading = false;

  constructor() {
    makeObservable(this, {
      loading: observable,
      setLoading: action,
    });
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}

export default LoadingStore;
