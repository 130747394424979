import React from 'react';
import { Route } from 'react-router-dom';
import Signup from 'components/investor/signup';
import Profile from 'components/investor/profile';
import Opportunities from 'components/investor/opportunities';
import BuyerProfile from 'components/investor/buyer_profile';
import FundBuyer from 'components/investor/fund_buyer';

export default (
  <>
    <Route path="signup" element={<Signup />} />
    <Route path="profile/edit" element={<Signup edit />} />
    <Route path="profile" element={<Profile />} />
    <Route path="opportunities" element={<Opportunities />} />
    <Route path="buyer/:id/invest" element={<FundBuyer />} />
    <Route path="buyer/:id" element={<BuyerProfile />} />
  </>
);
