import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import TopHero from 'components/home/landing/large/top_hero';
import Section1 from 'components/home/landing/large/section1';
import Section2 from 'components/home/landing/large/section2';
import BottomHero from 'components/home/landing/large/bottom_hero';

const Large = () => (
  <Flex direction="row" justifyContent="center" w="100%" pb="50">
    <Flex
      flexShrink={0}
      alignItems="flex-start"
      bgColor="white"
      direction="column"
      gap="173px"
      w="100%"
    >
      <Box mt={80} pos="relative" w="100%">
        <Flex
          alignItems="center"
          direction="column"
          gap={120}
          pos="relative"
          w="100%"
        >
          <TopHero />
          <Section1 />
          <Section2 />
          <BottomHero />
        </Flex>
      </Box>
    </Flex>
  </Flex>
);

export default Large;
