import React from 'react';
import {
  AbsoluteCenter,
  Center,
  CircularProgress,
  CircularProgressProps,
} from '@chakra-ui/react';

export default ({
  absolute,
  noPositioning,
}: {
  absolute?: boolean;
  noPositioning?: boolean;
}) => {
  const CenterComponent = absolute ? AbsoluteCenter : Center;
  const defaultPositioning: CircularProgressProps = noPositioning
    ? {}
    : {
      w: 40,
      h: 40,
      marginTop: -20,
      position: 'absolute',
    };

  return (
    <CenterComponent>
      <CircularProgress
        isIndeterminate
        color="primary.500"
        {...defaultPositioning}
      />
    </CenterComponent>
  );
};
